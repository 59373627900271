import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import edit from './../../../assets/icons/edit.svg';
import axios from "axios";
import swal from 'sweetalert';
import Modal from "react-modal";
import HoursToTime from '../../../utils/HoursToTime';
import DatePicker from "react-datepicker";
import { format, parseISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import FormDropdown from '../../common/form/FormDropdown';

const customStyles = {
  content: {
    top: "0",
    left: "auto",
    right: "0",
    bottom: "0",
    margin: "0",
    zIndex: "9999",
    border: "none",
    borderRadius: "0",
    padding: "0",
    position: "absolute",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    outline: "none",
  },
};

const timeLogDefaultValues = {
  id: null,
  task_id: null,
  user_id: null,
  note: null,
  date: new Date().toISOString().slice(0, 10),
  spent_time: null,
  non_billable_time: null,
  task_status_id: null
}

function DailyTimeLogs({ assignee }) {
  const todayDate = new Date();
  const history = useHistory();
  const [myTimeLogs, setMyTimeLogs] = useState([]);
  const [timelogDate, setTimelogDate] = useState(todayDate);
  const [editTimelogDate, setEditTimelogDate] = useState(timeLogDefaultValues.date);
  const [dateError, setDateError] = useState();
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [timeLogEditModalIsOpen, setTimeLogEditModalIsOpen] = useState(false);
  const [editTimeLog, setEditTimeLog] = useState(timeLogDefaultValues);
  const [editTimeLogErrorList, setEditTimeLogErrorList] = useState([]);
  const [taskStatuses, setTaskStatuses] = useState([]);
  const [assigneeId, setAssigneeId] = useState(localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_id`));

  const setPageData = useCallback(async () => {
    setLoading(true);
    let isMounted = true;
    if (isMounted) {
      let endpoints = [
        `/api/view-task-statuses`,
      ];
      Promise.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
        ([
          { data: taskStatuses },
        ]) => {
          if (taskStatuses.status === 200) {
            setTaskStatuses(taskStatuses.taskStatuses);
          }
          setLoading(false);
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const getTimeLogs = useCallback(async () => {
    setLoading(true);
    let isMounted = true;

    if (isMounted) {
      axios.get(`/api/my-all-timelogs/${assigneeId}/${format(timelogDate, 'Y-MM-d')}`).then(res => {
        if (res.data.status === 200) {
          setMyTimeLogs(res.data.timelogs)
        }
        setLoading(false);
      });
    }
    return () => {
      isMounted = false
    }
  }, [assigneeId, timelogDate]);

  useEffect(() => {
    setPageData();
    getTimeLogs();
  // eslint-disable-next-line
  }, [timelogDate, assigneeId]);

  useEffect(() => {
    if (assignee) {
      setAssigneeId(assignee);
    }
    // eslint-disable-next-line
  }, [assignee]);

  const handleDateChange = (date) => {
    if (date > todayDate) {
      setDateError('You selected a future date');
    } else {
      setTimelogDate(date);
      setDateError('');
    }
  }

  const setEditTimeLogData = useCallback(async (timeLogId) => {
    setLoading(true);
    setEditTimeLogErrorList([]);

    await axios.get(`/api/edit-time-log/${timeLogId}`).then((res) => {
      if (res.data.status === 200) {
        setEditTimeLog({...res.data.timeLog, task_status_id: res.data.timeLog.sub_task.task_status_id});
        setEditTimelogDate(res.data.timeLog.date)
      } else if (res.data.status === 404) {
        swal({
          title: "Error",
          text: res.data.message,
          icon: "error",
          className: "error-box",
          timer: 3000,
          buttons: false,
        });
        history.push(`/my-time-logs`);
      }
      setLoading(false);
    });
  },[history]);

  const updateTimeLog = (e) => {
    e.preventDefault();
    setPending(true);
    
    let isValid = true;
    const formData = new FormData();
    formData.append("task_status_id", editTimeLog.task_status_id ? editTimeLog.task_status_id : "");
    formData.append("non_billable_time", editTimeLog.non_billable_time ? editTimeLog.non_billable_time : "");
    formData.append("spent_time", editTimeLog.spent_time ? editTimeLog.spent_time : "");
    formData.append("date", editTimelogDate);
    formData.append("note", editTimeLog.note ? editTimeLog.note : "");
    formData.append("_method", "PUT");

    if (isValid) {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .post(`/api/update-time-log/${editTimeLog.id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            if (res.data.status === 200) {
              swal({
                title: "Success",
                text: res.data.message,
                icon: "success",
                className: "success-box",
                timer: 3000,
                buttons: false,
              });
              getTimeLogs();
              closeTimeLogEditModal();
            } else if (res.data.status === 422) {
              swal({
                title: "Error",
                text: "All Fields are mandatory",
                icon: "error",
                className: "error-box",
                timer: 3000,
                buttons: false,
              });
              setEditTimeLogErrorList(res.data.errors);
            } else if (res.data.status === 404) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "error",
                className: "error-box",
                timer: 3000,
                buttons: false,
              });
              getTimeLogs();
            }
            setPending(false);
          });
      });
    }
  }

  const deleteTimeLog = (e, id) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Record",
      className: "delete-box",
      buttons: {
        delete: {
          text: "Yes, Delete now",
          className: "colored-btn warning",
        },
      },
      dangerMode: true,
    }).then((result) => {
      if (result === "delete") {
        axios.delete(`/api/delete-time-log/${id}`).then((res) => {
          if (res.data.status === 200) {
            swal({
              title: "Success",
              text: res.data.message,
              icon: "success",
              className: "success-box",
              timer: 3000,
              buttons: false,
            });
            getTimeLogs();
            closeTimeLogEditModal();
          } else if (res.data.status === 404) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          } else if (res.data.status === 405) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          }
        });
      }
    });
  };

  const getDropdownOptions = (dataList) => {
    return dataList.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  };

  const openTimeLogEditModal = (e, timeLogId) => {
    e.preventDefault();
    setTimeLogEditModalIsOpen(true);
    setEditTimeLogData(timeLogId);
  }

  const closeTimeLogEditModal = () => {
    setTimeLogEditModalIsOpen(false);
    setEditTimeLog([]);
    setEditTimelogDate(null)
  }

  const handleInput = (e) => {
    setEditTimeLog({ ...editTimeLog, [e.target.name]: e.target.value });
  };

  const handleEditTimeLogDropdown = (item, action) => {
    setEditTimeLog({ ...editTimeLog, [action.name]: item.value });
  };

  return (
    <div className={`${loading ? 'page-loading' : ''} daily-timelog-wrapper`}>
      <form>
        <div className="form-row">
          <div className="form-group col">
            <DatePicker
              selected={timelogDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              maxDate={todayDate}
            />
            {
              dateError &&
              <small  className='text-danger'>{dateError}</small>
            }
          </div>
        </div>
      </form>

      <div className='daily-time-logs'>
        <h2 className='pb-2'>Daily Time Logs</h2>
        <div className='daily-time-logs-table-wrp'>
          <div className='daily-time-logs-table'>
            <div className="tasks-heading-wrp">
              <div className="project">Project</div>
              <div className="task">Parent Task</div>
              <div className="task">Sub Task</div>
              <div className="notes">Notes</div>
              <div className="date">Date</div>
              <div className="lstatus">Status</div>
              <div className="non-billable">Non billable</div>
              <div className="spent">Spent</div>
              <div className="actions">Actions</div>
            </div>
            <div className='daily-tl-item-wrp'>
              {
                myTimeLogs && myTimeLogs.map(task =>
                  <div key={task.id} className='daily-tl-item'>
                    <div className="project">{task.sub_task.parent_task.project.name}</div>
                    <div className="task">{`${task.sub_task.parent_task.id} - ${task.sub_task.parent_task.task_name}`}</div>
                    <div className="task">{`${task.sub_task.id} - ${task.sub_task.sub_task_name}`}</div>
                    <div className="notes">{task.note}</div>
                    <div className="date">
                      {format(parseISO(task.date), 'do MMM yyyy')}
                    </div>
                    <div className={`lstatus ${task.sub_task.task_status.color}`}>{task.sub_task.task_status.name}</div>
                    <div className="non-billable">{task.non_billable_time ? HoursToTime(parseFloat(task.non_billable_time)) : HoursToTime(0)}</div>
                    <div className="spent">{task.spent_time ? HoursToTime(parseFloat(task.spent_time)) : HoursToTime(0)}</div>
                    <div className="actions">
                      <div className='action-icon-wrp'>
                        <div className='action-item'>
                          <a href="#22" target="_blank" rel="noopener noreferrer" onClick={(e) => openTimeLogEditModal(e, task.id)}>
                            <img src={edit} alt="Edit" />
                          </a>
                        </div>
                        {/* <div className='action-item'>
                          <a href="#22" target="_blank" rel="noopener noreferrer">
                            <img src={trash} alt="Delete" />
                          </a>
                        </div> */}
                        {/* <button
                          type="button"
                          disabled={loading}
                          onClick={(e) => deleteTimeLog(e, task.id)}
                          className="colored-btn warning delete"
                        >
                          <img src={trash} alt="Delete" />
                        </button> */}
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={timeLogEditModalIsOpen}
        onRequestClose={closeTimeLogEditModal}
        style={customStyles}
        ariaHideApp={false}
        closeTimeoutMS={500}
        className="create-parent-task-modal"
      >
        <div>
          {/* <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              onClick={closeTimeLogEditModal}
            ></button>
          </div> */}
          <div className="modal-body">
            <div className="create-parent-task">
              <div className="form-wrp">
                <form encType="multipart/form-data" onSubmit={updateTimeLog} id="TIMELOG_FORM">
                  <div className="form-inner-wrp">
                    <div className="form-content-wrp">
                      <div className="task-header">
                        <h2>Update Time Log</h2>
                      </div>
                      <div className="task-content">
                        <div className="form-row">
                          <div className="form-group col col-md-6">
                            <label htmlFor="date" className="form-label">
                              Date
                            </label>
                            <DatePicker
                              selected={editTimelogDate}
                              onChange={(date) => setEditTimelogDate(format(date, "yyyy-MM-dd"))}
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                            />
                            <small className="text-danger">
                              {editTimeLogErrorList.date}
                            </small>
                          </div>
                          <div className="form-group col col-md-6">
                            <label
                              htmlFor="task_status"
                              className="form-label"
                            >
                              Task Status
                            </label>
                            <FormDropdown
                              value={editTimeLog.task_status_id}
                              name="task_status_id"
                              id="task_status_id"
                              options={getDropdownOptions(taskStatuses)}
                              isSearchable={true}
                              isDisabled={pending}
                              onChange={handleEditTimeLogDropdown}
                              placeholder="Task Status"
                            />
                            <small className="text-danger">
                              {editTimeLogErrorList.task_status_id}
                            </small>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col col-md-6">
                            <label htmlFor="spent_time" className="form-label">
                              Spent Time (Hours)
                            </label>
                            <input
                              type="number"
                              name="spent_time"
                              onChange={handleInput}
                              value={editTimeLog.spent_time}
                              min="0"
                              max="9999"
                              step="0.01"
                              id="spent_time"
                              className="form-control"
                              aria-label=""
                            />
                            <small className="text-danger">
                              {editTimeLogErrorList.spent_time}
                            </small>
                          </div>
                          <div className="form-group col col-md-6">
                            <label htmlFor="non_billable_time" className="form-label">
                              Non Billable Time (Hours)
                            </label>
                            <input
                              type="number"
                              name="non_billable_time"
                              onChange={handleInput}
                              value={editTimeLog.non_billable_time}
                              min="0"
                              max="9999"
                              step="0.01"
                              id="non_billable_time"
                              className="form-control"
                              aria-label=""
                            />
                            <small className="text-danger">
                              {editTimeLogErrorList.non_billable_time}
                            </small>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="note" className="form-label">
                            Note
                          </label>
                          <input
                            type="text"
                            name="note"
                            onChange={handleInput}
                            value={editTimeLog.note}
                            className="form-control"
                            id="note"
                            autoComplete="off"
                          />
                          <small className="text-danger">
                            {editTimeLogErrorList.note}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="button-wrp" style={{ justifyContent: "space-between" }}>
                      <button
                        type="button"
                        onClick={(e) => deleteTimeLog(e, editTimeLog.id)}
                        className="colored-btn warning delete with-icon"
                      >
                        Delete Time Log
                      </button>
                      <button
                        disabled={pending ? "disabled" : ""}
                        type="submit"
                        className="btn btn-primary"
                      >
                        <span className={`spinner-border spinner-border-sm me-3 ${!pending ? "d-none" : ""}`} role="status" aria-hidden="true"></span>
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DailyTimeLogs