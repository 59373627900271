import React from 'react';
import dateFormat from "dateformat";

import icon from './../../assets/icons/logo.svg';
import HoursToTime from '../../utils/HoursToTime';
import Avatar from '../common/Avatar';

function TasksAccordion({ tasks, openParentTaskCreateModal, openTaskEditModal }) {
    return (
        <div className='tasks-wrp'>
            <div className='tasks-items-wrp'>
                <div className="accordion" id="task-group">
                    <div className='tasks-heading-wrp'>
                        <div className='main-title-wrp'>
                            <div className='task-name'>Task</div>
                        </div>
                        <div className='sub-title-wrp'>
                            <div className='assignee'>Assignee</div>
                            <div className='due-date'>Due Date</div>
                            <div className='status-wrp'>Status</div>
                            <div className='estimate'>Estimate</div>
                            <div className='spent'>Spent</div>
                            <div className='priority'>Priority</div>
                        </div>
                    </div>

                    {tasks.map(task => (
                        <div key={task.parentTask.id} className="accordion-item">
                            <div className="accordion-header" id={`parent-${task.parentTask.id}`}>
                                <div className='task-header collapsed' data-bs-toggle='collapse' data-bs-target={`#collapse-${task.parentTask.id}`} aria-expanded="false" aria-controls={`collapse-${task.parentTask.id}`}>
                                    <div className='task-content-wrp'>
                                        <span className='accordion-icon'></span>
                                        <div className='task-name-wrp'>
                                            <div className='task-name'>{`${task.parentTask.id} - ${task.parentTask.task_name}`}</div>
                                            <div className='btn-wrp'>
                                                <button className="btn btn-table" onClick={() => openTaskEditModal(task.parentTask.id)} data-bs-toggle='collapse'>View Task</button>
                                            </div>
                                        </div>
                                        <div className='task-detail-wrp'>
                                            <div className='assignee'>
                                                <div className='image-wrp'>
                                                    <div className='image-item'>
                                                        <Avatar
                                                            src={task.parentTask.owner.profile_picture ? `${process.env.REACT_APP_BACKEND_URL}/${task.parentTask.owner.profile_picture}` : icon}
                                                            alt={task.parentTask.id}
                                                            className="avatar-small"
                                                            name={task.parentTask.owner.name}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='due-date'>{dateFormat(task.parentTask.due_date, "dS mmmm yyyy")}</div>
                                            {task.parentTask.task_status && 
                                                <div className='status-wrp'>
                                                    <span className={`status ${task.parentTask.task_status.color} small`}>{task.parentTask.task_status.name}</span>
                                                </div>
                                            }
                                            <div className='estimate'>{HoursToTime(task.parentTask.total_estimate_time)}</div>
                                            <div className='spent'>{HoursToTime(task.parentTask.total_spent_time)}</div>
                                            {task.parentTask.task_priority && 
                                                <div className='priority'>
                                                    <span className={`priority-icon flag-${task.parentTask.task_priority.color}`}></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id={`collapse-${task.parentTask.id}`} className="accordion-collapse collapse" aria-labelledby={`parent-${task.parentTask.id}`} data-bs-parent="#task-group">
                                <div className="accordion-body">
                                    {task.subTasks.map(subTask => (
                                        <div key={subTask.id} className='task-content-wrp'>
                                            <span className='accordion-icon'></span>
                                            <div className='task-name-wrp'>
                                                <div className='task-name'>{`${subTask.id} - ${subTask.sub_task_name}`}</div>
                                            </div>
                                            <div className='task-detail-wrp'>
                                                <div className='assignee'>
                                                    <div className='image-wrp'>
                                                        <div className='image-item'>
                                                            <Avatar
                                                                src={subTask.assignee.profile_picture ? `${process.env.REACT_APP_BACKEND_URL}/${subTask.assignee.profile_picture}` : icon}
                                                                alt={subTask.id}
                                                                className="avatar-small"
                                                                name={subTask.assignee.name}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='due-date'>{dateFormat(subTask.due_date, "dS mmmm yyyy")}</div>
                                                {subTask.task_status && 
                                                    <div className='status-wrp'>
                                                        <span className={`status ${subTask.task_status.color} small`}>{subTask.task_status.name}</span>
                                                    </div>
                                                }
                                                <div className='estimate'>{HoursToTime(subTask.estimate_time)}</div>
                                                <div className='spent'>{HoursToTime(subTask.total_spent_time)}</div>
                                                {task.parentTask.task_priority &&
                                                    <div className='priority'>
                                                        <span className={`priority-icon flag-${task.parentTask.task_priority.color}`}></span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                    {/* <div key="sub-task" className='task-content-wrp'>
                                        <span className='accordion-icon'></span><div className="add-task-wrp" onClick={() => openTaskEditModal(task.parentTask.id)} data-bs-toggle=''>Add Sub Task</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    ))}
                    <div key="add-task" className="accordion-item">
                        <div className="accordion-header" id={`parent-add-task`}>
                            <div className='task-header collapsed' data-bs-toggle='' data-bs-target={`#collapse-add-task`} aria-expanded="false" aria-controls={`collapse-add-task`}>
                                <div className='task-content-wrp'>
                                    <div className="add-task-wrp" onClick={() => openParentTaskCreateModal(tasks[0].parentTask.task_phase_id)} data-bs-toggle=''>Add Task</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TasksAccordion