import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Modal from "react-modal";
import dateFormat from "dateformat";
import parse from 'html-react-parser';
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import upload from "./../../../assets/icons/logo-upload.svg";
import icon from "./../../../assets/icons/logo.svg";
import Header from "../../../layouts/Header";
import RightSideBar from "../../../layouts/RightSideBar";
import Avatar from "../../common/Avatar";
import FormDropdown from "../../common/form/FormDropdown";
import FloatingTimer from "../../common/FloatingTimer";
import TaskPhaseSection from "./TaskPhaseSection";
import FormWYSIWYG from "../../common/form/FormWYSIWYG";
import CommentsSection from "../../common/CommentsSection";

const subTaskDefaultValues = {
  sub_task_name: "",
  estimate_time: "",
  task_type_id: null,
  task_status_id: null,
  assignee_id: null,
  assignee: null,
  qa_assignee_id: null,
  qa_assignee: null,
  id: null
}

const customStyles = {
  content: {
    top: "0",
    left: "auto",
    right: "0",
    bottom: "0",
    margin: "0",
    zIndex: "9999",
    border: "none",
    borderRadius: "0",
    padding: "0",
    position: "absolute",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    outline: "none",
  },
};

const ViewProject = (props) => {
  const projectId = props.match.params.id;
  const hiddenFileInput = useRef(null);
  const searchTextInput = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [image, setImage] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [totalTasks, setTotalTasks] = useState(0);
  const [totalCompletedTasks, setTotalCompletedTasks] = useState(0);
  const [daysLeft, setDaysLeft] = useState(0);
  const [projectModalIsOpen, setProjectModalIsOpen] = useState(false);
  const [parentTaskCreateModalIsOpen, setParentTaskCreateModalIsOpen] = useState(false);
  const [parentTaskEditModalIsOpen, setParentTaskEditModalIsOpen] = useState(false);
  const [taskEditModalIsOpen, setTaskEditModalIsOpen] = useState(false);
  const [pending, setPending] = useState(false);
  const [projectErrorList, setProjectErrorList] = useState([]);
  const [parentTaskErrorList, setParentTaskErrorList] = useState([]);
  const [editParentTaskErrorList, setEditParentTaskErrorList] = useState([]);
  const [editSubTaskErrorList, setEditSubTaskErrorList] = useState([]);
  const [subTaskErrorList, setSubTaskErrorList] = useState([]);
  const [projectDateRange, setProjectDateRange] = useState([]);
  const [parentTaskDateRange, setParentTaskDateRange] = useState([]);
  const [editParentTaskDateRange, setEditParentTaskDateRange] = useState([]);
  const [editSubTaskDateRange, setEditSubTaskDateRange] = useState([]);
  const [subTaskDateRange, setSubTaskDateRange] = useState([]);
  const [projectManagers, setProjectManagers] = useState([]);
  const [qaManagers, setQaManagers] = useState([]);
  const [projectStatuses, setProjectStatuses] = useState([]);
  const [projectPriorities, setProjectPriorities] = useState([]);
  const [projectSupportPlans, setProjectSupportPlans] = useState([]);
  const [projectPhases, setProjectPhases] = useState([]);
  const [projectCategories, setProjectCategories] = useState([]);
  const [taskStatuses, setTaskStatuses] = useState([]);
  const [taskAssignees, setTaskAssignees] = useState([]);
  const [taskPriorities, setTaskPriorities] = useState([]);
  const [taskPhases, setTaskPhases] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [pmImage, setPmImage] = useState(true);
  const [qaImage, setQaImage] = useState(true);
  const [parentTaskQaImage, setParentTaskQaImage] = useState(true);
  const [editParentTaskQaImage, setEditParentTaskQaImage] = useState(true);
  const [editParentTaskOwnerImage, setEditParentTaskOwnerImage] = useState(true);
  const [editSubTaskAssigneeImage, setEditSubTaskAssigneeImage] = useState(true);
  const [editSubTaskQaAssigneeImage, setEditSubTaskQaAssigneeImage] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState({categories: []});
  const [selectedPhases, setSelectedPhases] = useState({phases: []});
  const [searchInput, setSearch] = useState({
    search_text: "",
    task_status_id: "",
    task_assignee_id: "",
  });
  const [parentTask, setParentTask] = useState([]);
  const [parentTaskDescription, setParentTaskDescription] = useState("");
  const [editParentTask, setEditParentTask] = useState([]);
  const [editParentTaskDescription, setEditParentTaskDescription] = useState("");
  const [subTask, setSubTask] = useState(subTaskDefaultValues);
  const [subTaskQaAssigneeImage, setSubTaskQaAssigneeImage] = useState(true);
  const [subTaskDescription, setSubTaskDescription] = useState("");
  const [subTasks, setSubTasks] = useState([]);
  const [editSubTask, setEditSubTask] = useState(subTaskDefaultValues);
  const [editSubTaskDescription, setEditSubTaskDescription] = useState("");
  const [parentTaskUploads, setParentTaskUploads] = useState([]);
  const [editParentTaskUploads, setEditParentTaskUploads] = useState([]);
  const [subTaskUploads, setSubTaskUploads] = useState([]);
  const [editSubTaskUploads, setEditSubTaskUploads] = useState([]);
  const [showSubTaskForm, setShowSubTaskForm] = useState(false);
  const [showEditSubTaskForm, setShowEditSubTaskForm] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const parentTaskID = queryParameters.get("parenttaskid");
  const subTaskID = queryParameters.get("subtaskid");

  if (!localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_token`)) {
    history.push("/login");
    swal("Unauthorized", "Authorization Required", "error");
  } else if (
    !props.allowedRoles.includes(
      localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_role`)
    )
  ) {
    history.push("/403");
  }

  const resetParentTaskForm = () => {
    setParentTask([]);
    setParentTaskDescription("");
    setParentTaskDateRange([]);
    setParentTaskUploads([]);
    setParentTaskQaImage(false);
    setParentTaskErrorList([]);
  };

  const resetSubTaskForm = () => {
    setSubTask(subTaskDefaultValues);
    setSubTaskDescription("");
    setSubTaskDateRange([]);
    setSubTaskUploads([]);
    setSubTaskErrorList([]);
    setShowSubTaskForm(false);
  };

  const resetEditSubTaskForm = () => {
    setEditSubTask(subTaskDefaultValues);
    setEditSubTaskDescription("");
    setEditSubTaskDateRange([]);
    setEditParentTaskUploads([]);
    setEditSubTaskErrorList([]);
    setShowEditSubTaskForm(false);
  };

  const resetTaskModal = () => {
    resetSubTaskForm();
    resetEditSubTaskForm();
    setProjectData();
    setEditTaskData(editParentTask.id);
  };

  const setEditTaskData = useCallback(async (parentTaskId) => {
    setLoading(true);
    setEditParentTaskErrorList([]);

    await axios.get(`/api/edit-parent-task/${parentTaskId}`).then((res) => {
      if (res.data.status === 200) {
        setEditParentTask(res.data.parentTask);
        setEditParentTaskDescription(res.data.parentTask.task_description ? res.data.parentTask.task_description : "");
        setEditParentTaskQaImage(res.data.parentTask.qa_assignee_id ? true : false);
        setEditParentTaskOwnerImage(res.data.parentTask.owner_id ? true : false);
        setEditParentTaskDateRange([new Date(res.data.parentTask.start_date), new Date(res.data.parentTask.due_date)]);
        setSubTasks(res.data.subTasks);
      } else if (res.data.status === 404) {
        swal({
          title: "Error",
          text: res.data.message,
          icon: "error",
          className: "error-box",
          timer: 3000,
          buttons: false,
        });
        history.push(`/view-project/${project.id}`);
      }
      setLoading(false);
    });
  },[history, project.id]);

  const setEditSubTaskData = useCallback(async (subTaskId) => {
    setLoading(true);
    setEditSubTaskErrorList([]);

    await axios.get(`/api/edit-sub-task/${subTaskId}`).then((res) => {
      if (res.data.status === 200) {
        setEditSubTask({
          sub_task_name: res.data.subTask.sub_task_name ? res.data.subTask.sub_task_name : "",
          estimate_time: res.data.subTask.estimate_time ? res.data.subTask.estimate_time : "",
          task_type_id: res.data.subTask.task_type_id ? res.data.subTask.task_type_id : null,
          task_status_id: res.data.subTask.task_status_id ? res.data.subTask.task_status_id : null,
          assignee_id: res.data.subTask.assignee_id ? res.data.subTask.assignee_id : null,
          assignee: res.data.subTask.assignee ? res.data.subTask.assignee : null,
          qa_assignee_id: res.data.subTask.qa_assignee_id ? res.data.subTask.qa_assignee_id : null,
          qa_assignee: res.data.subTask.qa_assignee ? res.data.subTask.qa_assignee : null,
          id: res.data.subTask.id ? res.data.subTask.id : null
        });
        setEditSubTaskDescription(res.data.subTask.sub_task_description ? res.data.subTask.sub_task_description : "");
        setEditSubTaskAssigneeImage(res.data.subTask.assignee_id ? true : false);
        setEditSubTaskQaAssigneeImage(res.data.subTask.qa_assignee_id ? true : false);
        setEditSubTaskDateRange([new Date(res.data.subTask.start_date), new Date(res.data.subTask.due_date)]);
      } else if (res.data.status === 404) {
        swal({
          title: "Error",
          text: res.data.message,
          icon: "error",
          className: "error-box",
          timer: 3000,
          buttons: false,
        });
        history.push(`/view-project/${project.id}`);
      }
      setLoading(false);
    });
  },[history, project.id]);

  const setProjectData = useCallback(async () => {
    setLoading(true);
    setProjectErrorList([]);

    let searchText = searchInput.search_text ? searchInput.search_text : "null";
    let taskStatusId = searchInput.task_status_id ? searchInput.task_status_id : "null";
    let taskAssigneeId = searchInput.task_assignee_id ? searchInput.task_assignee_id : "null";

    await axios.get(`/api/edit-project/${projectId}/${searchText}/${taskStatusId}/${taskAssigneeId}`)
      .then((res) => {
        if (res.data.status === 200) {
          const projectData = res.data.project;
          setProject(projectData);
          setProject({...projectData, max_hours_limit: Math.floor(projectData.max_hours_limit / 60)});
          setImage({ logo: projectData.logo });
          setSelectedPhases({ phases: projectData.phases });
          setSelectedCategories({ categories: projectData.categories });
          setProjectDateRange([new Date(projectData.start_date), new Date(projectData.deadline)]);
          setTotalTasks(projectData.total_tasks ? projectData.total_tasks : 0);
          setTotalCompletedTasks(projectData.total_completed_tasks ? projectData.total_completed_tasks : 0);
          setDaysLeft(projectData.days_left ? projectData.days_left : 0);
          setTasks(res.data.taskData);
          setPmImage(projectData.project_manager_id ? true : false);
          setQaImage(projectData.qa_manager_id ? true : false);
          setParentTask({...parentTask, qa_assignee_id: projectData.qa_manager_id, qa_assignee: projectData.qa_manager});
          setParentTaskQaImage(projectData.qa_manager_id ? true : false);
        } else if (res.data.status === 404) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "error",
            className: "error-box",
            timer: 3000,
            buttons: false,
          });
          history.push("/all-projects");
        }
        setLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    projectId,
    searchInput.search_text,
    searchInput.task_assignee_id,
    searchInput.task_status_id,
  ]);

  const setPageData = useCallback(async (projectId) => {
    setLoading(true);
    let isMounted = true;
    if (isMounted) {
      let endpoints = [
        `/api/all-clients`,
        `/api/all-users`,
        `/api/all-qa-users`,
        `/api/all-project-statuses`,
        `/api/all-project-priorities`,
        `/api/all-project-support-plans`,
        `/api/all-project-phases`,
        `/api/all-project-categories`,
        `/api/view-task-priorities`,
        `/api/view-task-statuses`,
        `/api/view-task-types`,
        `/api/all-project-task-phases/${projectId}`,
        `/api/all-users`,
      ];
      Promise.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
        ([
          { data: allClients },
          { data: allUsers },
          { data: allQaUsers },
          { data: projectStatuses },
          { data: projectPriorities },
          { data: projectSupportPlans },
          { data: projectPhases },
          { data: projectCategories },
          { data: taskPriorities },
          { data: taskStatuses },
          { data: taskTypes },
          { data: taskPhases },
          { data: taskAssignees },
        ]) => {
          if (allClients.status === 200) {
            setClientList(allClients.clients);
          }
          if (allUsers.status === 200) {
            setProjectManagers(allUsers.users);
          }
          if (allQaUsers.status === 200) {
            setQaManagers(allQaUsers.users);
          }
          if (projectStatuses.status === 200) {
            setProjectStatuses(projectStatuses.projectStatuses);
          }
          if (projectPriorities.status === 200) {
            setProjectPriorities(projectPriorities.projectPriorities);
          }
          if (projectSupportPlans.status === 200) {
            setProjectSupportPlans(projectSupportPlans.projectSupportPlans);
          }
          if (projectPhases.status === 200) {
            setProjectPhases(projectPhases.projectPhases);
          }
          if (projectCategories.status === 200) {
            setProjectCategories(projectCategories.projectCategories);
          }
          if (taskPriorities.status === 200) {
            setTaskPriorities(taskPriorities.taskPriorities);
          }
          if (taskStatuses.status === 200) {
            setTaskStatuses(taskStatuses.taskStatuses);
          }
          if (taskTypes.status === 200) {
            setTaskTypes(taskTypes.taskTypes);
          }
          if (taskPhases.status === 200) {
            setTaskPhases(taskPhases.projectPhases);
          }
          if (taskAssignees.status === 200) {
            setTaskAssignees(taskAssignees.users);
          }
          setLoading(false);
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setPageData(projectId);
    setProjectData();
    if (searchTextInput.current) {
      searchTextInput.current.focus();
    }

    if (parentTaskID) {
      openTaskEditModal(parentTaskID);
    }
    // eslint-disable-next-line
  }, [searchInput, projectId]);

  const updateProject = (e) => {
    e.preventDefault();
    setPending(true);

    let maxHoursLimit = project.max_hours_limit
      ? parseInt(project.max_hours_limit) * 60
      : 0;
    let startingDate = "";
    let endingDate = "";

    let isValid = true;
    if (projectDateRange && projectDateRange.length > 0) {
      let newStartDate = projectDateRange[0];
      let newEndDate = projectDateRange[1];
      if (newStartDate !== null && newEndDate !== null) {
        startingDate = `${newStartDate.getFullYear()}-${
          newStartDate.getMonth() + 1
        }-${newStartDate.getDate()}`;
        endingDate = `${newEndDate.getFullYear()}-${
          newEndDate.getMonth() + 1
        }-${newEndDate.getDate()}`;
      } else {
        setProjectErrorList({
          ...projectErrorList,
          project_duration: "Please Select Project Duration",
        });
        isValid = false;
        setPending(false);
      }
    } else {
      setProjectErrorList({
        ...projectErrorList,
        project_duration: "Please Select Project Duration",
      });
      isValid = false;
      setPending(false);
    }

    const formData = new FormData();
    formData.append("client_id", project.client_id ? project.client_id : "");
    formData.append("logo", image.logo ? image.logo : "");
    formData.append("name", project.name ? project.name : "");
    formData.append("description", project.description ? project.description : "");
    formData.append("staging_url", project.staging_url ? project.staging_url : "");
    formData.append("production_url", project.production_url ? project.production_url : "");
    formData.append("spec_url", project.spec_url ? project.spec_url : "");
    formData.append("design_url", project.design_url ? project.design_url : "");
    formData.append("project_manager_id",project.project_manager_id ? project.project_manager_id : "");
    formData.append("qa_manager_id", project.qa_manager_id ? project.qa_manager_id : "");
    formData.append("project_status_id", project.project_status_id ? project.project_status_id : "");
    formData.append("project_status_reason", project.project_status_reason ? project.project_status_reason : "");
    formData.append("priority_id", project.priority_id ? project.priority_id : "");
    formData.append("support_plan_id", project.support_plan_id ? project.support_plan_id : "");
    formData.append("phases", selectedPhases.phases);
    formData.append("categories", selectedCategories.categories);
    formData.append("max_hours_limit", maxHoursLimit);
    formData.append("start_date", startingDate);
    formData.append("deadline", endingDate);
    formData.append("_method", "PUT");

    if (isValid) {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .post(`/api/update-project/${project.id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            if (res.data.status === 200) {
              swal({
                title: "Success",
                text: res.data.message,
                icon: "success",
                className: "success-box",
                timer: 3000,
                buttons: false,
              });
              setProjectData();
              setProjectErrorList([]);
              closeProjectModal();
            } else if (res.data.status === 422) {
              swal({
                title: "Error",
                text: "All Fields are mandatory",
                icon: "error",
                className: "error-box",
                timer: 3000,
                buttons: false,
              });
              setProjectErrorList(res.data.errors);
            } else if (res.data.status === 404) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "error",
                className: "error-box",
                timer: 3000,
                buttons: false,
              });
              history.push(`/view-project/${project.id}`);
            }
            setPending(false);
          });
      });
    }
  };

  const deleteProject = (e, id) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Record",
      className: "delete-box",
      buttons: {
        delete: {
          text: "Yes, Delete now",
          className: "colored-btn warning",
        },
        archive: {
          text: "Archive Instead",
          className: "colored-btn gentle",
        },
      },
      dangerMode: true,
    }).then((result) => {
      if (result === "delete") {
        axios.delete(`/api/delete-project/${id}`).then((res) => {
          if (res.data.status === 200) {
            swal({
              title: "Success",
              text: res.data.message,
              icon: "success",
              className: "success-box",
              timer: 3000,
              buttons: false,
            });
            history.push("/all-projects");
          } else if (res.data.status === 404) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          } else if (res.data.status === 405) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          }
        });
      } else if (result === "archive") {
        axios.delete(`/api/archive-project/${id}`).then((res) => {
          if (res.data.status === 200) {
            swal({
              title: "Success",
              text: res.data.message,
              icon: "success",
              className: "success-box",
              timer: 3000,
              buttons: false,
            });
            history.push("/all-projects");
          } else if (res.data.status === 404) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          } else if (res.data.status === 405) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          }
        });
      }
    });
  };

  const createParentTask = (e) => {
    e.preventDefault();
    setPending(true);

    let startingDate = "";
    let endingDate = "";

    let isValid = true;
    if (parentTaskDateRange && parentTaskDateRange.length > 0) {
      let newStartDate = parentTaskDateRange[0];
      let newEndDate = parentTaskDateRange[1];
      if (newStartDate !== null && newEndDate !== null) {
        startingDate = `${newStartDate.getFullYear()}-${
          newStartDate.getMonth() + 1
        }-${newStartDate.getDate()}`;
        endingDate = `${newEndDate.getFullYear()}-${
          newEndDate.getMonth() + 1
        }-${newEndDate.getDate()}`;
      } else {
        setParentTaskErrorList({
          ...parentTaskErrorList,
          parent_task_duration: "Please Select Task Duration",
        });
        isValid = false;
        setPending(false);
      }
    } else {
      setParentTaskErrorList({
        ...parentTaskErrorList,
        parent_task_duration: "Please Select Task Duration",
      });
      isValid = false;
      setPending(false);
    }

    const formData = new FormData();
    formData.append("project_id", project.id ? project.id : "");
    formData.append("task_name", parentTask.task_name ? parentTask.task_name : "");
    formData.append("task_description", parentTaskDescription ? parentTaskDescription : "");
    formData.append("owner_id", parentTask.owner_id ? parentTask.owner_id : "");
    formData.append("qa_assignee_id", parentTask.qa_assignee_id ? parentTask.qa_assignee_id : "");
    formData.append("task_status_id", 1); // Not Started
    formData.append("task_priority_id", parentTask.task_priority_id ? parentTask.task_priority_id : "");
    formData.append("task_phase_id", parentTask.task_phase_id ? parentTask.task_phase_id : "");
    formData.append("start_date", startingDate);
    formData.append("due_date", endingDate);
    if (parentTaskUploads.length > 0) {
      for (let i = 0; i < parentTaskUploads.length; i++) {
        formData.append("uploads[]", parentTaskUploads[i]);
      }
    }

    if (isValid) {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios({
          method: "post",
          url: `/api/store-parent-task`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
          if (res.data.status === 200) {
            swal({
              title: "Success",
              text: res.data.message,
              icon: "success",
              className: "success-box",
              timer: 3000,
              buttons: false,
            });
            resetParentTaskForm();
            setProjectData();
            closeParentTaskCreateModal();
            openTaskEditModal(res.data.parentTask.id);
          } else if (res.data.status === 422) {
            swal({
              title: "Error",
              text: "All Fields are mandatory",
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
            setParentTaskErrorList(res.data.errors);
          }
        });
        setPending(false);
      });
    }
  };

  const updateParentTask = (e) => {
    e.preventDefault();
    setPending(true);

    let startingDate = "";
    let endingDate = "";

    let isValid = true;
    if (editParentTaskDateRange && editParentTaskDateRange.length > 0) {
      let newStartDate = editParentTaskDateRange[0];
      let newEndDate = editParentTaskDateRange[1];
      if (newStartDate !== null && newEndDate !== null) {
        startingDate = `${newStartDate.getFullYear()}-${
          newStartDate.getMonth() + 1
        }-${newStartDate.getDate()}`;
        endingDate = `${newEndDate.getFullYear()}-${
          newEndDate.getMonth() + 1
        }-${newEndDate.getDate()}`;
      } else {
        setEditParentTaskErrorList({
          ...editParentTaskErrorList,
          parent_task_duration: "Please Select Task Duration",
        });
        isValid = false;
        setPending(false);
      }
    } else {
      setEditParentTaskErrorList({
        ...editParentTaskErrorList,
        parent_task_duration: "Please Select Task Duration",
      });
      isValid = false;
      setPending(false);
    }

    const formData = new FormData();
    formData.append("project_id", project.id ? project.id : "");
    formData.append("task_name", editParentTask.task_name ? editParentTask.task_name : "");
    formData.append("task_description", editParentTaskDescription ? editParentTaskDescription : "");
    formData.append("task_priority_id", editParentTask.task_priority_id ? editParentTask.task_priority_id : "");
    formData.append("task_phase_id", editParentTask.task_phase_id ? editParentTask.task_phase_id : "");
    formData.append("owner_id", editParentTask.owner_id ? editParentTask.owner_id : "");
    formData.append("qa_assignee_id", editParentTask.qa_assignee_id ? editParentTask.qa_assignee_id : "");
    formData.append("start_date", startingDate);
    formData.append("due_date", endingDate);
    if (editParentTaskUploads.length > 0) {
      for (let i = 0; i < editParentTaskUploads.length; i++) {
        formData.append("uploads[]", editParentTaskUploads[i]);
      }
    }
    formData.append("_method", "PUT");

    if (isValid) {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .post(`/api/update-parent-task/${editParentTask.id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            if (res.data.status === 200) {
              swal({
                title: "Success",
                text: res.data.message,
                icon: "success",
                className: "success-box",
                timer: 3000,
                buttons: false,
              });
              // resetEditParentTaskForm();
              setProjectData();
              closeParentTaskEditModal();
              openTaskEditModal(editParentTask.id);
            } else if (res.data.status === 422) {
              swal({
                title: "Error",
                text: "All Fields are mandatory",
                icon: "error",
                className: "error-box",
                timer: 3000,
                buttons: false,
              });
              setEditParentTaskErrorList(res.data.errors);
            } else if (res.data.status === 404) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "error",
                className: "error-box",
                timer: 3000,
                buttons: false,
              });
              history.push(`/view-project/${project.id}`);
            }
            setPending(false);
          });
      });
    }
  };

  const deleteParentTask = (e, id) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Record",
      className: "delete-box",
      buttons: {
        delete: {
          text: "Yes, Delete now",
          className: "colored-btn warning",
        },
        archive: {
          text: "Archive Instead",
          className: "colored-btn gentle",
        },
      },
      dangerMode: true,
    }).then((result) => {
      if (result === "delete") {
        axios.delete(`/api/delete-parent-task/${id}`).then((res) => {
          if (res.data.status === 200) {
            swal({
              title: "Success",
              text: res.data.message,
              icon: "success",
              className: "success-box",
              timer: 3000,
              buttons: false,
            });
            setProjectData();
            closeTaskEditModal();
          } else if (res.data.status === 404) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          } else if (res.data.status === 405) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          }
        });
      } else if (result === "archive") {
        axios.delete(`/api/archive-parent-task/${id}`).then((res) => {
          if (res.data.status === 200) {
            swal({
              title: "Success",
              text: res.data.message,
              icon: "success",
              className: "success-box",
              timer: 3000,
              buttons: false,
            });
            resetTaskModal();
          } else if (res.data.status === 404) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          } else if (res.data.status === 405) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          }
        });
      }
    });
  };

  const createSubTask = (e) => {
    e.preventDefault();
    setPending(true);

    let startingDate = "";
    let endingDate = "";

    let isValid = true;
    if (subTaskDateRange && subTaskDateRange.length > 0) {
      let newStartDate = subTaskDateRange[0];
      let newEndDate = subTaskDateRange[1];
      if (newStartDate !== null && newEndDate !== null) {
        startingDate = `${newStartDate.getFullYear()}-${newStartDate.getMonth() + 1}-${newStartDate.getDate()}`;
        endingDate = `${newEndDate.getFullYear()}-${newEndDate.getMonth() + 1}-${newEndDate.getDate()}`;
      } else {
        setSubTaskErrorList({...subTaskErrorList, sub_task_duration: "Please Select Sub Task Duration"});
        isValid = false;
        setPending(false);
      }
    } else {
      setSubTaskErrorList({...subTaskErrorList, sub_task_duration: "Please Select Sub Task Duration"});
      isValid = false;
      setPending(false);
    }

    const formData = new FormData();
    formData.append("parent_task_id", editParentTask.id ? editParentTask.id : "");
    formData.append("sub_task_name", subTask.sub_task_name ? subTask.sub_task_name : "");
    formData.append("sub_task_description", subTaskDescription ? subTaskDescription : "");
    formData.append("estimate_time", subTask.estimate_time ? subTask.estimate_time : "");
    formData.append("task_type_id", subTask.task_type_id ? subTask.task_type_id : "");
    formData.append("task_status_id", subTask.task_status_id ? subTask.task_status_id : "");
    formData.append("qa_assignee_id", subTask.qa_assignee_id ? subTask.qa_assignee_id : "");
    formData.append("assignee_id", subTask.assignee_id ? subTask.assignee_id : "");
    formData.append("start_date", startingDate);
    formData.append("due_date", endingDate);
    if (subTaskUploads.length > 0) {
      for (let i = 0; i < subTaskUploads.length; i++) {
        formData.append("uploads[]", subTaskUploads[i]);
      }
    }

    if (isValid) {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios({
          method: "post",
          url: `/api/store-sub-task`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
          if (res.data.status === 200) {
            swal({
              title: "Success",
              text: res.data.message,
              icon: "success",
              className: "success-box",
              timer: 3000,
              buttons: false,
            });
            resetTaskModal();
          } else if (res.data.status === 422) {
            swal({
              title: "Error",
              text: "All Fields are mandatory",
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
            setSubTaskErrorList(res.data.errors);
          }
        });
        setPending(false);
      });
    }
  };

  const updateSubTask = (e) => {
    e.preventDefault();
    setPending(true);

    let startingDate = "";
    let endingDate = "";

    let isValid = true;
    if (editSubTaskDateRange && editSubTaskDateRange.length > 0) {
      let newStartDate = editSubTaskDateRange[0];
      let newEndDate = editSubTaskDateRange[1];
      if (newStartDate !== null && newEndDate !== null) {
        startingDate = `${newStartDate.getFullYear()}-${newStartDate.getMonth() + 1}-${newStartDate.getDate()}`;
        endingDate = `${newEndDate.getFullYear()}-${newEndDate.getMonth() + 1}-${newEndDate.getDate()}`;
      } else {
        setEditSubTaskErrorList({...editSubTaskErrorList, sub_task_duration: "Please Select Sub Task Duration"});
        isValid = false;
        setPending(false);
      }
    } else {
      setEditSubTaskErrorList({...editSubTaskErrorList, sub_task_duration: "Please Select Sub Task Duration"});
      isValid = false;
      setPending(false);
    }
    
    const formData = new FormData();
    formData.append("parent_task_id", editParentTask.id ? editParentTask.id : "");
    formData.append("sub_task_name", editSubTask.sub_task_name ? editSubTask.sub_task_name : "");
    formData.append("sub_task_description", editSubTaskDescription ? editSubTaskDescription : "");
    formData.append("estimate_time", editSubTask.estimate_time ? editSubTask.estimate_time : "");
    formData.append("task_type_id", editSubTask.task_type_id ? editSubTask.task_type_id : "");
    formData.append("task_status_id", editSubTask.task_status_id ? editSubTask.task_status_id : "");
    formData.append("assignee_id", editSubTask.assignee_id ? editSubTask.assignee_id : "");
    formData.append("qa_assignee_id", editSubTask.qa_assignee_id ? editSubTask.qa_assignee_id : "");
    formData.append("start_date", startingDate);
    formData.append("due_date", endingDate);
    if (editSubTaskUploads.length > 0) {
      for (let i = 0; i < editSubTaskUploads.length; i++) {
        formData.append("uploads[]", editSubTaskUploads[i]);
      }
    }
    formData.append("_method", "PUT");

    if (isValid) {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .post(`/api/update-sub-task/${editSubTask.id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            if (res.data.status === 200) {
              swal({
                title: "Success",
                text: res.data.message,
                icon: "success",
                className: "success-box",
                timer: 3000,
                buttons: false,
              });
              resetTaskModal();
            } else if (res.data.status === 422) {
              swal({
                title: "Error",
                text: "All Fields are mandatory",
                icon: "error",
                className: "error-box",
                timer: 3000,
                buttons: false,
              });
              setEditSubTaskErrorList(res.data.errors);
            } else if (res.data.status === 404) {
              swal({
                title: "Error",
                text: res.data.message,
                icon: "error",
                className: "error-box",
                timer: 3000,
                buttons: false,
              });
              history.push(`/view-project/${project.id}`);
            }
            setPending(false);
          });
      });
    }
  };

  const deleteSubTask = (e, id) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Record",
      className: "delete-box",
      buttons: {
        delete: {
          text: "Yes, Delete now",
          className: "colored-btn warning",
        },
        archive: {
          text: "Archive Instead",
          className: "colored-btn gentle",
        },
      },
      dangerMode: true,
    }).then((result) => {
      if (result === "delete") {
        axios.delete(`/api/delete-sub-task/${id}`).then((res) => {
          if (res.data.status === 200) {
            swal({
              title: "Success",
              text: res.data.message,
              icon: "success",
              className: "success-box",
              timer: 3000,
              buttons: false,
            });
            resetTaskModal();
          } else if (res.data.status === 404) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          } else if (res.data.status === 405) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          }
        });
      } else if (result === "archive") {
        axios.delete(`/api/archive-sub-task/${id}`).then((res) => {
          if (res.data.status === 200) {
            swal({
              title: "Success",
              text: res.data.message,
              icon: "success",
              className: "success-box",
              timer: 3000,
              buttons: false,
            });
            resetTaskModal();
          } else if (res.data.status === 404) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          } else if (res.data.status === 405) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          }
        });
      }
    });
  };

  const getEditSubTaskForm = (e, subTaskId) => {
    e.preventDefault();
    resetEditSubTaskForm();
    setEditSubTaskData(subTaskId);
    setShowEditSubTaskForm(true);
  };

  const handleInput = (e) => {
    setProject({ ...project, [e.target.name]: e.target.value });
  };

  const handleParentTaskInput = (e) => {
    setParentTask({ ...parentTask, [e.target.name]: e.target.value });
  };

  const handleEditParentTaskInput = (e) => {
    setEditParentTask({ ...editParentTask, [e.target.name]: e.target.value });
  };

  const handleSubTaskInput = (e) => {
    setSubTask({ ...subTask, [e.target.name]: e.target.value });
  };

  const handleEditSubTaskInput = (e) => {
    setEditSubTask({ ...editSubTask, [e.target.name]: e.target.value });
  };

  const handleParentTaskUploads = (e) => {
    const filesArray = [];
    for (let i = 0; i < e.target.files.length; i++) {
      filesArray.push(e.target.files[i]);
    }
    setParentTaskUploads(filesArray);
  };

  const handleEditParentTaskUploads = (e) => {
    const filesArray = [];
    for (let i = 0; i < e.target.files.length; i++) {
      filesArray.push(e.target.files[i]);
    }
    setEditParentTaskUploads(filesArray);
  };

  const handleSubTaskUploads = (e) => {
    const filesArray = [];
    for (let i = 0; i < e.target.files.length; i++) {
      filesArray.push(e.target.files[i]);
    }
    setSubTaskUploads(filesArray);
  };

  const handleEditSubTaskUploads = (e) => {
    const filesArray = [];
    for (let i = 0; i < e.target.files.length; i++) {
      filesArray.push(e.target.files[i]);
    }
    setEditSubTaskUploads(filesArray);
  };

  const handleSearchText = (e) => {
    setSearch({ ...searchInput, search_text: e.target.value });
  };

  const handleTaskStatus = (value) => {
    setSearch({ ...searchInput, task_status_id: value });
  };

  const handleTaskAssignee = (value) => {
    setSearch({ ...searchInput, task_assignee_id: value });
  };

  const deletePmImage = () => {
    setPmImage(!pmImage);
    setProject({ ...project, project_manager: null, project_manager_id: "" });
  };

  const deleteQaImage = () => {
    setQaImage(!qaImage);
    setProject({ ...project, qa_manager: null, qa_manager_id: "" });
  };

  const deleteParentTaskQaImage = () => {
    setParentTaskQaImage(!parentTaskQaImage);
    setParentTask({ ...parentTask, qa_assignee: null, qa_assignee_id: "" });
  };

  const deleteEditParentTaskQaImage = () => {
    setEditParentTaskQaImage(!editParentTaskQaImage);
    setEditParentTask({ ...editParentTask, qa_assignee: null, qa_assignee_id: "" });
  };

  const deleteEditParentTaskOwnerImage = () => {
    setEditParentTaskOwnerImage(!editParentTaskOwnerImage);
    setEditParentTask({ ...editParentTask, owner: null, owner_id: "" });
  };

  const deleteEditSubTaskAssigneeImage = () => {
    setEditSubTaskAssigneeImage(!editSubTaskAssigneeImage);
    setEditSubTask({ ...editSubTask, assignee: null, assignee_id: "" });
  };

  const deleteEditSubTaskQaAssigneeImage = () => {
    setEditSubTaskQaAssigneeImage(!editSubTaskQaAssigneeImage);
    setEditSubTask({ ...editSubTask, qa_assignee: null, qa_assignee_id: "" });
  };

  const deleteSubTaskQaAssigneeImage = () => {
    setSubTaskQaAssigneeImage(!subTaskQaAssigneeImage);
    setSubTask({ ...subTask, qa_assignee: null, qa_assignee_id: "" });
  };

  const handleLogo = (e) => {
    setImage({ logo: e.target.files[0] });
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleUploadClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleCategoriesCheckboxes = (e) => {
    const { value, checked } = e.target;
    const { categories } = selectedCategories;
    if (checked) {
      setSelectedCategories({
        categories: [...categories, parseInt(value)],
      });
    } else {
      setSelectedCategories({
        categories: categories.filter((e) => e !== parseInt(value)),
      });
    }
  };

  const handlePhasesCheckboxes = (e) => {
    const { value, checked } = e.target;
    const { phases } = selectedPhases;
    if (checked) {
      setSelectedPhases({
        phases: [...phases, parseInt(value)],
      });
    } else {
      setSelectedPhases({
        phases: phases.filter((e) => e !== parseInt(value)),
      });
    }
  };

  const handleDropdown = (item, action) => {
    setProject({ ...project, [action.name]: item.value });
  };

  const handleParentTaskDropdown = (item, action) => {
    setParentTask({ ...parentTask, [action.name]: item.value });
  };

  const handleEditParentTaskDropdown = (item, action) => {
    setEditParentTask({ ...editParentTask, [action.name]: item.value });
  };

  const handleSubTaskDropdown = (item, action) => {
    setSubTask({ ...subTask, [action.name]: item.value });
  };

  const handleEditSubTaskDropdown = (item, action) => {
    setEditSubTask({ ...editSubTask, [action.name]: item.value });
  };

  const openProjectModal = (e) => {
    setProjectModalIsOpen(true);
  }

  const closeProjectModal = () => {
    setProjectModalIsOpen(false);
  }

  const openParentTaskCreateModal = (taskPhaseId) => {
    if (taskPhaseId) {
      setParentTask({ ...parentTask, task_phase_id: taskPhaseId });
    } else {
      setParentTask({ ...parentTask, task_phase_id: "" });
    }
    setParentTaskCreateModalIsOpen(true);
  }

  const closeParentTaskCreateModal = () => {
    setParentTaskCreateModalIsOpen(false);
  }

  const openParentTaskEditModal = (parentTaskId) => {
    setTaskEditModalIsOpen(false);
    setEditTaskData(parentTaskId);
    setParentTaskEditModalIsOpen(true);
  }

  const closeParentTaskEditModal = () => {
    setParentTaskEditModalIsOpen(false);
  }

  const openTaskEditModal = (parentTaskId) => {
    setParentTaskEditModalIsOpen(false);
    setEditTaskData(parentTaskId);
    setTaskEditModalIsOpen(true);
  }

  const closeTaskEditModal = () => {
    setShowSubTaskForm(false);
    setTaskEditModalIsOpen(false);
    setShowEditSubTaskForm(false);
    setShowSubTaskForm(false);
  }

  const getDropdownOptions = (dataList) => {
    return dataList.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  };

  const handleParentTaskDescription = (value) => {
    setParentTaskDescription(value);
  }

  const handleEditParentTaskDescription = (value) => {
    setEditParentTaskDescription(value);
  }

  const handleSubTaskDescription = (value) => {
    setSubTaskDescription(value);
  }

  const handleEditSubTaskDescription = (value) => {
    setEditSubTaskDescription(value);
  }

  return (
    <div className={`${loading ? "page-loading" : ""}`}>
      <Header
        title={project.name}
        type="variation-3"
        headerButton={true}
        headerButtonType="modal"
        headerButtonUrl={`/edit-project/${project.id}`}
        headerButtonTitle="Edit Project"
        project={project}
        // categories={categories}
        // phases={phases}
        openModal={openProjectModal}
      />
      <div className="main-content view-project-wrp has-right-sidebar">
        <div className="middle-content">
          <div className="form-wrp top-form-wrp">
            <form id="SEARCH_FORM">
              <div className="form-row">
                <div className="form-group col">
                  <input
                    type="text"
                    // disabled={ pending ? 'disabled' : '' }
                    ref={searchTextInput}
                    name="search_text"
                    onChange={handleSearchText}
                    value={searchInput.search_text}
                    className="form-control"
                    id="search_text"
                    placeholder="Search"
                  />
                </div>
                <div className="form-group col">
                  <FormDropdown
                    value={searchInput.task_status_id}
                    name="task_status_id"
                    id="task_status_id"
                    options={getDropdownOptions(taskStatuses)}
                    isSearchable={true}
                    isClearable={true}
                    isDisabled={pending}
                    onChange={(options) => !options ? handleTaskStatus("") : handleTaskStatus(options.value)}
                    placeholder="Task Status"
                  />
                </div>
                <div className="form-group col">
                  <FormDropdown
                    value={searchInput.task_assignee_id}
                    name="task_assignee_id"
                    id="task_assignee_id"
                    options={getDropdownOptions(taskAssignees)}
                    isSearchable={true}
                    isClearable={true}
                    isDisabled={pending}
                    onChange={(options) => !options ? handleTaskAssignee("") : handleTaskAssignee(options.value)}
                    placeholder="Assignee"
                  />
                </div>
                <div className="form-group col button-wrp">
                  <div
                    className="btn btn-primary"
                    onClick={() => openParentTaskCreateModal()}
                  >
                    Add Task
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="middle-content-inner-wrp">
            { projectPhases && projectPhases.length > 0 && 
              projectPhases.sort((a, b) => a.level - b.level).map((phase) => tasks.filter((item) => item.parentTask.task_phase_id === phase.id).length > 0 && (
              <TaskPhaseSection
                key={phase.id}
                projectPhases={projectPhases}
                phase={phase.id} 
                tasks={tasks} 
                openParentTaskCreateModal={openParentTaskCreateModal} 
                openTaskEditModal={openTaskEditModal}
              />
            ))}
          </div>
        </div>
        <RightSideBar
          project={project}
          tasks={tasks}
          totalSpent={project.total_spent}
          totalEstimate={project.total_estimate}
          daysLeft={daysLeft}
          totalTasks={totalTasks}
          totalCompletedTasks={totalCompletedTasks}
        />
      </div>
      <Modal
        isOpen={projectModalIsOpen}
        onRequestClose={closeProjectModal}
        style={customStyles}
        ariaHideApp={false}
        closeTimeoutMS={500}
      >
        <div>
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              onClick={closeProjectModal}
            ></button>
          </div>
          <div className="modal-body">
            <div className="view-project">
              <div className="form-wrp">
                <form
                  encType="multipart/form-data"
                  onSubmit={updateProject}
                  id="PROJECT_FORM"
                >
                  <div className="form-inner-wrp">
                    <div className="form-content-wrp">
                      <div className="form-left-col">
                        <div className="form-group">
                          <label
                            htmlFor="logo"
                            className="form-label logo-label"
                          >
                            Logo
                          </label>
                          <div
                            className="logo-wrp project-logo-wrp"
                            onClick={handleUploadClick}
                          >
                            <img
                              src={
                                selectedImage
                                  ? URL.createObjectURL(selectedImage)
                                  : image.logo
                                  ? `${process.env.REACT_APP_BACKEND_URL}/${image.logo}`
                                  : upload
                              }
                              className={
                                selectedImage
                                  ? "project-logo"
                                  : image.logo
                                  ? "project-logo"
                                  : ""
                              }
                              alt=""
                            />
                          </div>
                          <input
                            type="file"
                            name="logo"
                            ref={hiddenFileInput}
                            onChange={handleLogo}
                            style={{ display: "none" }}
                            className="form-control"
                            id="logo"
                          />
                          <small className="text-danger">
                            {projectErrorList.logo}
                          </small>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="project_duration"
                            className="form-label"
                          >
                            Project Duration
                          </label>
                          <DateRangePicker
                            onChange={setProjectDateRange}
                            value={projectDateRange}
                            format="yy-MM-dd"
                            yearPlaceholder="yy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            className="my-calender"
                            id="project_duration"
                          />
                          <small className="text-danger">
                            {projectErrorList.project_duration}
                          </small>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="max_hours_limit"
                            className="form-label"
                          >
                            Max hours Cap
                          </label>
                          <div className="max-hour-input-wrp">
                            <input
                              type="number"
                              name="max_hours_limit"
                              onBlur={handleInput}
                              defaultValue={project.max_hours_limit}
                              min="0"
                              max="9999"
                              step="0.01"
                              id="max_hours_limit"
                              className="form-control"
                              aria-label=""
                            />
                          </div>
                          <small className="text-danger">
                            {projectErrorList.max_hours_limit}
                          </small>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="support_plan_id"
                            className="form-label"
                          >
                            Support Plan
                          </label>
                          <FormDropdown
                            value={project.support_plan_id}
                            name="support_plan_id"
                            id="support_plan_id"
                            options={getDropdownOptions(projectSupportPlans)}
                            isSearchable={true}
                            isDisabled={pending}
                            onChange={handleDropdown}
                            placeholder="Support Plan"
                          />
                          <small className="text-danger">
                            {projectErrorList.support_plan_id}
                          </small>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="project_manager_id"
                            className="form-label"
                          >
                            Project Manager
                          </label>
                          { pmImage && project.project_manager ? (
                            <div className="project-manager-wrp">
                              <Avatar
                                src={
                                  project.project_manager.profile_picture
                                    ? `${process.env.REACT_APP_BACKEND_URL}/${project.project_manager.profile_picture}`
                                    : icon
                                }
                                alt={project.project_manager_id}
                                onClick={deletePmImage}
                                closeButton={true}
                                name={project.project_manager.name}
                              />
                            </div>
                          ) : (
                            <FormDropdown
                              value={project.project_manager_id}
                              name="project_manager_id"
                              id="project_manager_id"
                              options={getDropdownOptions(projectManagers)}
                              isSearchable={true}
                              isDisabled={pending}
                              onChange={handleDropdown}
                              placeholder="Project Manager"
                            />
                          )}
                          <small className="text-danger">
                            {projectErrorList.project_manager_id}
                          </small>
                        </div>
                        <div className="form-group">
                          <label htmlFor="qa_manager_id" className="form-label">
                            QA Manager
                          </label>
                          { qaImage && project.qa_manager ? (
                            <div className="qa-manager-wrp">
                              <Avatar
                                src={
                                  project.qa_manager.profile_picture
                                    ? `${process.env.REACT_APP_BACKEND_URL}/${project.qa_manager.profile_picture}`
                                    : icon
                                }
                                alt={project.qa_manager_id}
                                onClick={deleteQaImage}
                                closeButton={true}
                                name={project.qa_manager.name}
                              />
                            </div>
                          ) : (
                            <FormDropdown
                              value={project.qa_manager_id}
                              name="qa_manager_id"
                              id="qa_manager_id"
                              options={getDropdownOptions(qaManagers)}
                              isSearchable={true}
                              isDisabled={pending}
                              onChange={handleDropdown}
                              placeholder="QA Manager"
                            />
                          )}
                          <small className="text-danger">
                            {projectErrorList.qa_manager_id}
                          </small>
                        </div>
                      </div>
                      <div className="form-right-col">
                        <div className="title-wrp">
                          <h2>Project Details</h2>
                          <p>General Information about the project</p>
                        </div>
                        <div className="project-detail-row pd-first">
                          <div className="form-group col col-4">
                            <div className="form-group-wrp">
                              <div className="form-group">
                                <label htmlFor="name" className="form-label">
                                  Project Name
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  onBlur={handleInput}
                                  defaultValue={project.name}
                                  className="form-control"
                                  id="name"
                                  autoComplete="off"
                                />
                                <small className="text-danger">
                                  {projectErrorList.name}
                                </small>
                              </div>
                              <div className="form-group">
                                <FormDropdown
                                  value={project.client_id}
                                  name="client_id"
                                  id="client_id"
                                  options={getDropdownOptions(clientList)}
                                  isSearchable={true}
                                  isDisabled={pending}
                                  onChange={handleDropdown}
                                  placeholder="Select a Client"
                                />
                                <small className="text-danger">
                                  {projectErrorList.client_id}
                                </small>
                              </div>
                              <div className="form-group">
                                <FormDropdown
                                  value={project.project_status_id}
                                  name="project_status_id"
                                  id="project_status_id"
                                  options={getDropdownOptions(projectStatuses)}
                                  isSearchable={true}
                                  isDisabled={pending}
                                  onChange={handleDropdown}
                                  placeholder="Project Status"
                                />
                                <small className="text-danger">
                                  {projectErrorList.project_status_id}
                                </small>
                              </div>
                              <div className="form-group">
                                <FormDropdown
                                  value={project.priority_id}
                                  name="priority_id"
                                  id="priority_id"
                                  options={getDropdownOptions(projectPriorities)}
                                  isSearchable={true}
                                  isDisabled={pending}
                                  onChange={handleDropdown}
                                  placeholder="Project Priority"
                                />
                                <small className="text-danger">
                                  {projectErrorList.priority_id}
                                </small>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col col-8 description-col">
                            <div className="form-group">
                              <label
                                htmlFor="description"
                                className="form-label"
                              >
                                Brief Description about the Project
                              </label>
                              <textarea
                                name="description"
                                onChange={handleInput}
                                value={project.description}
                                className="form-control"
                                id="description"
                                style={{ height: "245px" }}
                              ></textarea>
                              <small className="text-danger">
                                {projectErrorList.description}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="project-detail-row pd-second">
                          <div className="form-group col col-5">
                            <div className="form-row">
                              <div className="form-group col col-6">
                                <label
                                  htmlFor="categories"
                                  className="form-label"
                                >
                                  Project Categories
                                </label>
                                {projectCategories &&
                                  projectCategories.map((category) => (
                                    <div
                                      className="form-check"
                                      key={`category-${category.id}`}
                                    >
                                      <input
                                        type="checkbox"
                                        name="category"
                                        onChange={handleCategoriesCheckboxes}
                                        value={category.id}
                                        defaultChecked={
                                          project.categories &&
                                          project.categories.includes(
                                            category.id
                                          )
                                            ? true
                                            : false
                                        }
                                        className="form-check-input"
                                        id={`category-${category.id}`}
                                      />
                                      <label
                                        htmlFor={`category-${category.id}`}
                                        className="form-check-label"
                                      >
                                        {category.name}
                                      </label>
                                    </div>
                                  ))}
                              </div>
                              <div className="form-group col col-6">
                                <label htmlFor="phases" className="form-label">
                                  Project Phases
                                </label>
                                {projectPhases &&
                                  projectPhases.map((phase) => (
                                    <div
                                      className="form-check"
                                      key={`category-${phase.id}`}
                                    >
                                      <input
                                        type="checkbox"
                                        name="phase"
                                        onChange={handlePhasesCheckboxes}
                                        value={phase.id}
                                        defaultChecked={
                                          project.phases &&
                                          project.phases.includes(phase.id)
                                            ? true
                                            : false
                                        }
                                        className="form-check-input"
                                        id={`phase-${phase.id}`}
                                      />
                                      <label
                                        htmlFor={`phase-${phase.id}`}
                                        className="form-check-label"
                                      >
                                        {phase.name}
                                      </label>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div className="form-group col col-7">
                            <div className="form-group">
                              <div className="input-label-left-variation">
                                <label
                                  htmlFor="staging_url"
                                  className="form-label"
                                >
                                  Staging URL
                                </label>
                                <input
                                  type="text"
                                  name="staging_url"
                                  onBlur={handleInput}
                                  defaultValue={project.staging_url}
                                  className="form-control"
                                  id="staging_url"
                                />
                                <small className="text-danger">
                                  {projectErrorList.staging_url}
                                </small>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="input-label-left-variation">
                                <label
                                  htmlFor="production_url"
                                  className="form-label"
                                >
                                  Production URL
                                </label>
                                <input
                                  type="text"
                                  name="production_url"
                                  onBlur={handleInput}
                                  defaultValue={project.production_url}
                                  className="form-control"
                                  id="production_url"
                                />
                                <small className="text-danger">
                                  {projectErrorList.production_url}
                                </small>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="input-label-left-variation">
                                <label
                                  htmlFor="spec_url"
                                  className="form-label"
                                >
                                  Spec URL
                                </label>
                                <input
                                  type="text"
                                  name="spec_url"
                                  onBlur={handleInput}
                                  defaultValue={project.spec_url}
                                  className="form-control"
                                  id="spec_url"
                                />
                                <small className="text-danger">
                                  {projectErrorList.spec_url}
                                </small>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="input-label-left-variation">
                                <label
                                  htmlFor="design_url"
                                  className="form-label"
                                >
                                  Design URL
                                </label>
                                <input
                                  type="text"
                                  name="design_url"
                                  onBlur={handleInput}
                                  defaultValue={project.design_url}
                                  className="form-control"
                                  id="design_url"
                                />
                                <small className="text-danger">
                                  {projectErrorList.design_url}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="button-wrp">
                      <button
                        type="button"
                        onClick={(e) => deleteProject(e, project.id)}
                        className="colored-btn warning delete with-icon"
                      >
                        Delete Project
                      </button>
                      <button
                        disabled={pending ? "disabled" : ""}
                        type="submit"
                        className="btn btn-primary"
                      >
                        <span
                          className={`spinner-border spinner-border-sm me-3 ${
                            !pending ? "d-none" : ""
                          }`}
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={parentTaskCreateModalIsOpen}
        onRequestClose={closeParentTaskCreateModal}
        style={customStyles}
        ariaHideApp={false}
        closeTimeoutMS={500}
        className="create-parent-task-modal"
      >
        <div>
          <div className="modal-body">
            <div className="create-parent-task">
              <div className="form-wrp">
                <form
                  encType="multipart/form-data"
                  onSubmit={createParentTask}
                  id="TASK_FORM"
                >
                  <div className="form-inner-wrp">
                    <div className="form-content-wrp">
                      <div className="task-header">
                        <h2>Create a Task</h2>
                      </div>
                      <div className="task-content">
                        <div className="form-row">
                          <div className="form-group col col-md-8">
                            <label htmlFor="task_name" className="form-label">
                              Task Name
                            </label>
                            <input
                              type="text"
                              name="task_name"
                              onBlur={handleParentTaskInput}
                              defaultValue={parentTask.task_name}
                              className="form-control"
                              id="task_name"
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {parentTaskErrorList.task_name}
                            </small>
                          </div>
                          <div className="form-group col col-md-4">
                            <label
                              htmlFor="parent_task_duration"
                              className="form-label"
                            >
                              Start & End Date
                            </label>
                            <DateRangePicker
                              onChange={setParentTaskDateRange}
                              value={parentTaskDateRange}
                              format="yy-MM-dd"
                              yearPlaceholder="yy"
                              monthPlaceholder="mm"
                              dayPlaceholder="dd"
                              className="my-calender"
                              id="parent_task_duration"
                            />
                            <small className="text-danger">
                              {parentTaskErrorList.parent_task_duration}
                            </small>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="form-group">
                            <label
                              htmlFor="task_description"
                              className="form-label"
                            >
                              Description
                            </label>
                            <FormWYSIWYG
                              value={parentTask.task_description}
                              onChange={handleParentTaskDescription}
                              id={`task_description_${parentTask.id}`}
                              name="task_description"
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col col-md-4">
                            <label htmlFor="uploads" className="form-label">
                              Upload a file
                            </label>
                            <div className="upload-wrp">
                              <input
                                type="file"
                                name="uploads"
                                multiple
                                onChange={handleParentTaskUploads}
                                className="form-control"
                                id="uploads"
                              />
                            </div>
                            <small className="text-danger">
                              {parentTaskErrorList.uploads}
                            </small>
                          </div>
                          <div className="form-group col col-md-8">
                            <div className="form-row">
                              <div className="form-group col col-md-6">
                                <label
                                  htmlFor="task_priority_id"
                                  className="form-label"
                                >
                                  Priority
                                </label>
                                <FormDropdown
                                  value={parentTask.task_priority_id}
                                  name="task_priority_id"
                                  id="task_priority_id"
                                  options={getDropdownOptions(taskPriorities)}
                                  isSearchable={true}
                                  isDisabled={pending}
                                  onChange={handleParentTaskDropdown}
                                  placeholder="Task Priority"
                                />
                                <small className="text-danger">
                                  {parentTaskErrorList.task_priority_id}
                                </small>
                              </div>
                              <div className="form-group col col-md-6">
                                <label
                                  htmlFor="task_phase_id"
                                  className="form-label"
                                >
                                  Phase
                                </label>
                                <FormDropdown
                                  value={parentTask.task_phase_id}
                                  name="task_phase_id"
                                  id="task_phase_id"
                                  options={getDropdownOptions(taskPhases)}
                                  isSearchable={true}
                                  isDisabled={pending}
                                  onChange={handleParentTaskDropdown}
                                  placeholder="Task Phase"
                                />
                                <small className="text-danger">
                                  {parentTaskErrorList.task_phase_id}
                                </small>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col col-md-6">
                                <label
                                  htmlFor="owner_id"
                                  className="form-label"
                                >
                                  Task Owner
                                </label>
                                <FormDropdown
                                  value={parentTask.owner_id}
                                  name="owner_id"
                                  id="owner_id"
                                  options={getDropdownOptions(taskAssignees)}
                                  isSearchable={true}
                                  isDisabled={pending}
                                  onChange={handleParentTaskDropdown}
                                  placeholder="Task Owner"
                                />
                                <small className="text-danger">
                                  {parentTaskErrorList.owner_id}
                                </small>
                              </div>
                              <div className="form-group col col-md-6">
                                <label
                                  htmlFor="qa_assignee_id"
                                  className="form-label"
                                >
                                  QA Manager
                                </label>
                                { parentTaskQaImage && parentTask.qa_assignee ? (
                                  <div className="qa-manager-wrp">
                                    <Avatar
                                      src={
                                        parentTask.qa_assignee.profile_picture
                                          ? `${process.env.REACT_APP_BACKEND_URL}/${parentTask.qa_assignee.profile_picture}`
                                          : icon
                                      }
                                      alt={parentTask.qa_assignee_id}
                                      onClick={deleteParentTaskQaImage}
                                      closeButton={true}
                                      name={parentTask.qa_assignee.name}
                                    />
                                  </div>
                                ) : (
                                  <FormDropdown
                                    value={parentTask.qa_assignee_id}
                                    name="qa_assignee_id"
                                    id="qa_assignee_id"
                                    options={getDropdownOptions(qaManagers)}
                                    isSearchable={true}
                                    isDisabled={pending}
                                    onChange={handleParentTaskDropdown}
                                    placeholder="QA Manager"
                                  />
                                )}
                                <small className="text-danger">
                                  {parentTaskErrorList.qa_assignee_id}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="button-wrp">
                      <button
                        disabled={pending ? "disabled" : ""}
                        type="submit"
                        className="btn btn-table large"
                      >
                        <span
                          className={`spinner-border spinner-border-sm me-3 ${
                            !pending ? "d-none" : ""
                          }`}
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Create Task
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={parentTaskEditModalIsOpen}
        onRequestClose={closeParentTaskEditModal}
        style={customStyles}
        ariaHideApp={false}
        closeTimeoutMS={500}
        className="create-parent-task-modal"
      >
        <div>
          <div className="modal-body">
            <div className="create-parent-task">
              <div className="form-wrp">
                <form
                  encType="multipart/form-data"
                  onSubmit={updateParentTask}
                  id="TASK_FORM"
                >
                  <div className="form-inner-wrp">
                    <div className="form-content-wrp">
                      <div className="task-header">
                        <h2>Update Task</h2>
                      </div>
                      <div className="task-content">
                        <div className="form-row">
                          <div className="form-group col col-md-8">
                            <label htmlFor="task_name" className="form-label">
                              Task Name
                            </label>
                            <input
                              type="text"
                              name="task_name"
                              onBlur={handleEditParentTaskInput}
                              defaultValue={editParentTask.task_name}
                              className="form-control"
                              id="task_name"
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {editParentTaskErrorList.task_name}
                            </small>
                          </div>
                          <div className="form-group col col-md-4">
                            <label
                              htmlFor="parent_task_duration"
                              className="form-label"
                            >
                              Start & End Date
                            </label>
                            <DateRangePicker
                              onChange={setEditParentTaskDateRange}
                              value={editParentTaskDateRange}
                              format="yy-MM-dd"
                              yearPlaceholder="yy"
                              monthPlaceholder="mm"
                              dayPlaceholder="dd"
                              className="my-calender"
                              id="parent_task_duration"
                            />
                            <small className="text-danger">
                              {editParentTaskErrorList.parent_task_duration}
                            </small>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="form-group">
                            <label
                              htmlFor="task_description"
                              className="form-label"
                            >
                              Description
                            </label>
                            <FormWYSIWYG
                              value={editParentTask.task_description}
                              onChange={handleEditParentTaskDescription}
                              id={`edit_task_description_${editParentTask.id}`}
                              name="edit_task_description"
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col col-md-4">
                            <div className="form-group">
                              <label htmlFor="uploads" className="form-label">
                                Upload a file
                              </label>
                              <div className="upload-wrp">
                                <input
                                  type="file"
                                  name="uploads"
                                  multiple
                                  onChange={handleEditParentTaskUploads}
                                  className="form-control"
                                  id="uploads"
                                />
                              </div>
                              <small className="text-danger">
                                {editParentTaskErrorList.uploads}
                              </small>
                            </div>
                            <div className="form-group">
                              {editParentTask.uploads &&
                                JSON.parse(editParentTask.uploads).length >
                                  0 && (
                                  <div className="file-upload-wrp">
                                    <label
                                      htmlFor="uploads"
                                      className="form-label"
                                    >
                                      Files
                                    </label>
                                    <div className="upload-file-wrp">
                                      {JSON.parse(editParentTask.uploads).map(
                                        (upload) => (
                                          <a
                                            href={`${process.env.REACT_APP_BACKEND_URL}/${upload}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="upload-file-item"
                                          >{`${upload.split("/").pop()}`}</a>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group col col-md-8">
                            <div className="form-row">
                              <div className="form-group col col-md-6">
                                <label
                                  htmlFor="task_priority_id"
                                  className="form-label"
                                >
                                  Priority
                                </label>
                                <FormDropdown
                                  value={editParentTask.task_priority_id}
                                  name="task_priority_id"
                                  id="task_priority_id"
                                  options={getDropdownOptions(taskPriorities)}
                                  isSearchable={true}
                                  isDisabled={pending}
                                  onChange={handleEditParentTaskDropdown}
                                  placeholder="Task Priority"
                                />
                                <small className="text-danger">
                                  {editParentTaskErrorList.task_priority_id}
                                </small>
                              </div>
                              <div className="form-group col col-md-6">
                                <label
                                  htmlFor="task_phase_id"
                                  className="form-label"
                                >
                                  Phase
                                </label>
                                <FormDropdown
                                  value={editParentTask.task_phase_id}
                                  name="task_phase_id"
                                  id="task_phase_id"
                                  options={getDropdownOptions(taskPhases)}
                                  isSearchable={true}
                                  isDisabled={pending}
                                  onChange={handleEditParentTaskDropdown}
                                  placeholder="Task Phase"
                                />
                                <small className="text-danger">
                                  {editParentTaskErrorList.task_phase_id}
                                </small>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col col-md-6">
                                <label
                                  htmlFor="owner_id"
                                  className="form-label"
                                >
                                  Task Owner
                                </label>
                                { editParentTaskOwnerImage && editParentTask.owner ? (
                                  <div className="task-owner-wrp">
                                    <Avatar
                                      src={
                                        editParentTask.owner.profile_picture
                                          ? `${process.env.REACT_APP_BACKEND_URL}/${editParentTask.owner.profile_picture}`
                                          : icon
                                      }
                                      alt={editParentTask.owner_id}
                                      onClick={deleteEditParentTaskOwnerImage}
                                      closeButton={true}
                                      name={editParentTask.owner.name}
                                    />
                                  </div>
                                ) : (
                                  <FormDropdown
                                    value={editParentTask.owner_id}
                                    name="owner_id"
                                    id="owner_id"
                                    options={getDropdownOptions(taskAssignees)}
                                    isSearchable={true}
                                    isDisabled={pending}
                                    onChange={handleEditParentTaskDropdown}
                                    placeholder="Task Owner"
                                  />
                                )}
                                <small className="text-danger">
                                  {editParentTaskErrorList.owner_id}
                                </small>
                              </div>
                              <div className="form-group col col-md-6">
                                <label
                                  htmlFor="qa_assignee_id"
                                  className="form-label"
                                >
                                  QA Manager
                                </label>
                                { editParentTaskQaImage && editParentTask.qa_assignee ? (
                                  <div className="qa-manager-wrp">
                                    <Avatar
                                      src={
                                        editParentTask.qa_assignee
                                          .profile_picture
                                          ? `${process.env.REACT_APP_BACKEND_URL}/${editParentTask.qa_assignee.profile_picture}`
                                          : icon
                                      }
                                      alt={editParentTask.qa_assignee_id}
                                      onClick={deleteEditParentTaskQaImage}
                                      closeButton={true}
                                      name={editParentTask.qa_assignee.name}
                                    />
                                  </div>
                                ) : (
                                  <FormDropdown
                                    value={editParentTask.qa_assignee_id}
                                    name="qa_assignee_id"
                                    id="qa_assignee_id"
                                    options={getDropdownOptions(qaManagers)}
                                    isSearchable={true}
                                    isDisabled={pending}
                                    onChange={handleEditParentTaskDropdown}
                                    placeholder="Task Phase"
                                  />
                                )}
                                <small className="text-danger">
                                  {editParentTaskErrorList.qa_assignee_id}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="button-wrp" style={{ justifyContent: "space-between" }}>
                      <button 
                        disabled={pending ? "disabled" : ""}
                        type="button" 
                        className="btn btn-table large" 
                        onClick={() => openTaskEditModal(editParentTask.id)}
                      >
                        <span
                          className={`spinner-border spinner-border-sm me-3 ${
                            !pending ? "d-none" : ""
                          }`}
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Back
                      </button>
                      <button
                        disabled={pending ? "disabled" : ""}
                        type="submit"
                        className="btn btn-table large"
                      >
                        <span
                          className={`spinner-border spinner-border-sm me-3 ${
                            !pending ? "d-none" : ""
                          }`}
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Update Task
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={taskEditModalIsOpen}
        onRequestClose={closeTaskEditModal}
        style={customStyles}
        ariaHideApp={false}
        closeTimeoutMS={500}
        className="view-task-modal"
      >
        <div>
          <div className="modal-body">
            <div className="view-task">
              <div className="form-wrp">
                <div className="form-inner-wrp">
                  <div className="form-content-wrp">
                    <div className="form-left-col">
                      <form id="EDIT_TASK_FORM">
                        <div className="form-left-inner-wrp">
                          <div className="view-task-header">
                            <div className="task-priority">
                              <span className={`priority-flag flag-${editParentTask.task_priority ? editParentTask.task_priority.color : ""}`}></span>
                            </div>
                            {project &&
                              <Link to={`/view-project/${project.id}`} target="_blank">
                                <div className="image-wrp pt-2 pe-2">
                                  <Avatar
                                    src={project.logo ? `${process.env.REACT_APP_BACKEND_URL}/${project.logo}` : icon}
                                    alt={project.name}
                                    className="avatar"
                                    name={project.name}
                                  />
                                </div>
                              </Link>
                            }
                            <div className="task-name">
                              {`${editParentTask.id} - ${editParentTask.task_name}`}
                            </div>
                            <div className="task-status">
                              <span className={`status ${editParentTask.task_status ? editParentTask.task_status.color : ""}`}>
                                {editParentTask.task_status ? editParentTask.task_status.name : ""}
                              </span>
                            </div>
                          </div>
                          <div className="view-task-detail">
                            <div className="assignee-wrp">
                              <div className="task-owner">
                                <label
                                  htmlFor="owner_id"
                                  className="form-label"
                                >
                                  Owner
                                </label>
                                { editParentTask.owner && (
                                  <div className="image-wrp">
                                    <Avatar
                                      src={
                                        editParentTask.owner.profile_picture
                                          ? `${process.env.REACT_APP_BACKEND_URL}/${editParentTask.owner.profile_picture}`
                                          : icon
                                      }
                                      alt={editParentTask.owner_id}
                                      className="avatar-small"
                                      name={editParentTask.owner.name}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="task-qa">
                                <label
                                  htmlFor="qa_assignee_id"
                                  className="form-label"
                                >
                                  QA
                                </label>
                                { editParentTaskQaImage && editParentTask.qa_assignee && (
                                  <div className="image-wrp">
                                    <Avatar
                                      src={
                                        editParentTask.qa_assignee
                                          .profile_picture
                                          ? `${process.env.REACT_APP_BACKEND_URL}/${editParentTask.qa_assignee.profile_picture}`
                                          : icon
                                      }
                                      alt={editParentTask.qa_assignee_id}
                                      className="avatar-small"
                                      name={editParentTask.qa_assignee.name}
                                    />
                                  </div>
                                )}
                              </div>
                              {subTasks.length > 0 && (
                                <div className="task-assignees">
                                  <label
                                    htmlFor="sub_task_assignees"
                                    className="form-label"
                                  >
                                    Assignees
                                  </label>
                                  <div className="image-wrp">
                                    {[...new Map(subTasks.map((item) => [item.assignee["id"], item])).values()].map((subTask) => (
                                      <Avatar
                                        src={
                                          subTask.assignee.profile_picture
                                            ? `${process.env.REACT_APP_BACKEND_URL}/${subTask.assignee.profile_picture}`
                                            : icon
                                        }
                                        alt={subTask.assignee_id}
                                        className="avatar-small"
                                        name={subTask.assignee.name}
                                      />
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="track-time-wrp">
                              <div className="track-time-inner-wrp">
                                <div className="track-time estimate">
                                  <div className="time">
                                    {editParentTask.total_estimate_time
                                      ? editParentTask.total_estimate_time
                                      : 0}
                                  </div>
                                  <label
                                    htmlFor="total_estimate_time"
                                    className="form-label"
                                  >
                                    Estimate
                                  </label>
                                </div>
                                <div className="track-time billable">
                                  <div className="time">
                                    {editParentTask.total_spent_time -
                                      editParentTask.total_non_billable_time}
                                  </div>
                                  <label
                                    htmlFor="total_billable_time"
                                    className="form-label"
                                  >
                                    Billable
                                  </label>
                                </div>
                                <div className="track-time non-billable">
                                  <div className="time">
                                    {editParentTask.total_non_billable_time
                                      ? editParentTask.total_non_billable_time
                                      : 0}
                                  </div>
                                  <label
                                    htmlFor="total_non_billable_time"
                                    className="form-label"
                                  >
                                    Non-Billable
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="description-detail-wrp">
                            <div className="timeline-wrp">
                              <div className="date-timeline">
                                <label
                                  htmlFor="task_timeline"
                                  className="form-label"
                                >
                                  Timeline{" "}
                                  { editParentTask.days_left >= 0 ?
                                    <span className="remaining-days">
                                      {`${editParentTask.days_left} Days Remaining`}
                                    </span>
                                    :
                                    <span className="behind-days">
                                      {`${Math.abs(editParentTask.days_left)} Days Behind`}
                                    </span>
                                  }
                                </label>
                                <div className="date">{`${dateFormat(
                                  editParentTask.start_date,
                                  "dS mmmm yyyy"
                                )} - ${dateFormat(
                                  editParentTask.due_date,
                                  "dS mmmm yyyy"
                                )}`}</div>
                              </div>
                              {editParentTask.uploads &&
                                JSON.parse(editParentTask.uploads).length >
                                  0 && (
                                  <div className="file-upload-wrp">
                                    <label
                                      htmlFor="uploads"
                                      className="form-label"
                                    >
                                      Files
                                    </label>
                                    <div className="upload-file-wrp">
                                      {JSON.parse(editParentTask.uploads).map(
                                        (upload) => (
                                          <a
                                            href={`${process.env.REACT_APP_BACKEND_URL}/${upload}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="upload-file-item"
                                          >{`${upload.split("/").pop()}`}</a>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                            </div>
                            {editParentTask.task_description && 
                              <div className="description-wrp">
                                <label
                                  htmlFor="task_description"
                                  className="form-label"
                                >
                                  Description
                                </label>
                                <div className="description-block html-desc">
                                  {parse(editParentTask.task_description)}
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                        <div className="button-wrp">
                          <button
                            type="button"
                            disabled={
                              showEditSubTaskForm || showSubTaskForm
                                ? "disabled"
                                : ""
                            }
                            onClick={(e) =>
                              deleteParentTask(e, editParentTask.id)
                            }
                            className="colored-btn warning delete with-icon"
                          >
                            Delete Task
                          </button>
                          <button
                            type="button"
                            disabled={
                              showEditSubTaskForm || showSubTaskForm
                                ? "disabled"
                                : ""
                            }
                            onClick={() =>
                              openParentTaskEditModal(editParentTask.id)
                            }
                            className="btn btn-table large"
                          >
                            Edit Task
                          </button>
                        </div>
                      </form>
                    </div>
                    <div
                      className={`form-right-col ${
                        showSubTaskForm ? "add-subtask-active" : ""
                      }`}
                    >
                      <div className="title-wrp">
                        <h2>Sub Tasks</h2>
                      </div>
                      <div className="sub-tasks-popup-wrp">
                        <div className="sub-tasks-popup-inner-wrp">
                          <div
                            className={`sub-tasks-content-wrp ${
                              showSubTaskForm ? "add-subtask-active" : ""
                            }`}
                          >
                            <div
                              className="sub-task-accordion"
                              id="subtask-group"
                            >
                              {subTasks.map((subTask) => (
                                <>
                                  <div
                                    key={subTask.id}
                                    className="accordion-item"
                                  >
                                    <div
                                      className="accordion-header"
                                      id={`sub-${subTask.id}`}
                                    >
                                      <div
                                        className="task-header collapsed"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse-sub-${subTask.id}`}
                                        aria-expanded="false"
                                        aria-controls={`collapse-sub-${subTask.id}`}
                                      >
                                        <div className="task-content-wrp">
                                          <span className="accordion-icon"></span>
                                          <div className="avatar-title-wrp">
                                            { subTask.assignee && (
                                              <div className="image-wrp">
                                                <Avatar
                                                  src={
                                                    subTask.assignee
                                                      .profile_picture
                                                      ? `${process.env.REACT_APP_BACKEND_URL}/${subTask.assignee.profile_picture}`
                                                      : icon
                                                  }
                                                  alt={subTask.assignee_id}
                                                  className="avatar-small"
                                                  name={subTask.assignee.name}
                                                />
                                              </div>
                                            )}
                                            <div className="task-name">
                                              {`${subTask.id} - ${subTask.sub_task_name}`}
                                            </div>
                                          </div>
                                          <div className="status-wrp">
                                            <span className={`status ${subTask.task_status ? subTask.task_status.color : ""} small`}>
                                              {subTask.task_status ? subTask.task_status.name : ""}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      id={`collapse-sub-${subTask.id}`}
                                      className={`accordion-collapse collapse ${(subTaskID && parseInt(subTaskID) === parseInt(subTask.id)) ? 'show' : ''}`}
                                      aria-labelledby={`sub-${subTask.id}`}
                                      data-bs-parent="#subtask-group"
                                    >
                                      <div className="accordion-body">
                                        <div className="project-detail-row">
                                          <div className="form-outer-group-wrp">
                                            <div className="form-group-wrp">
                                              <div className="timeline-main-wrp">
                                                <div className="timeline-priority-wrp">
                                                  <div className="timeline-wrp">
                                                    <label
                                                      htmlFor="task_timeline"
                                                      className="form-label"
                                                    >
                                                      Timeline{" "}
                                                      { subTask.days_left >= 0 ?
                                                        <span className="remaining-days">
                                                          {`${subTask.days_left} Days Remaining`}
                                                        </span>
                                                        :
                                                        <span className="behind-days">
                                                          {`${Math.abs(subTask.days_left)} Days Behind`}
                                                        </span>
                                                      }
                                                    </label>
                                                    <div className="date">{`${dateFormat(
                                                      subTask.start_date,
                                                      "dS mmmm yyyy"
                                                    )} - ${dateFormat(
                                                      subTask.due_date,
                                                      "dS mmmm yyyy"
                                                    )}`}</div>
                                                  </div>
                                                  <div className="priority-wrp">
                                                    <label
                                                      htmlFor="task_status"
                                                      className="form-label"
                                                    >
                                                      Status
                                                    </label>
                                                    <div className="priority">
                                                      {subTask.task_status
                                                        ? subTask.task_status
                                                            .name
                                                        : ""}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="track-time-wrp">
                                                  <div className="track-time-inner-wrp">
                                                    <div className="track-time estimate">
                                                      <div className="time">
                                                        {subTask.estimate_time
                                                          ? subTask.estimate_time
                                                          : 0}
                                                      </div>
                                                      <label
                                                        htmlFor="total_estimate_time"
                                                        className="form-label"
                                                      >
                                                        Estimate
                                                      </label>
                                                    </div>
                                                    <div className="track-time billable">
                                                      <div className="time">
                                                        {subTask.total_spent_time -
                                                          subTask.total_non_billable_time}
                                                      </div>
                                                      <label
                                                        htmlFor="total_billable_time"
                                                        className="form-label"
                                                      >
                                                        Billable
                                                      </label>
                                                    </div>
                                                    <div className="track-time non-billable">
                                                      <div className="time">
                                                        {subTask.total_non_billable_time
                                                          ? subTask.total_non_billable_time
                                                          : 0}
                                                      </div>
                                                      <label
                                                        htmlFor="total_non_billable_time"
                                                        className="form-label"
                                                      >
                                                        Non-Billable
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              {subTask.uploads &&
                                                JSON.parse(subTask.uploads)
                                                  .length > 0 && (
                                                  <div className="file-upload-wrp">
                                                    <label
                                                      htmlFor="uploads"
                                                      className="form-label"
                                                    >
                                                      Files
                                                    </label>
                                                    <div className="upload-file-wrp">
                                                      {JSON.parse(
                                                        subTask.uploads
                                                      ).map((upload) => (
                                                        <a
                                                          href={`${process.env.REACT_APP_BACKEND_URL}/${upload}`}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                          className="upload-file-item"
                                                        >{`${upload
                                                          .split("/")
                                                          .pop()}`}</a>
                                                      ))}
                                                    </div>
                                                  </div>
                                                )}
                                              {subTask.sub_task_description &&
                                                <div className="description-wrp">
                                                  <label
                                                    htmlFor="sub_task_description"
                                                    className="form-label"
                                                  >
                                                    Description
                                                  </label>
                                                  <div className="description html-desc">
                                                    {parse(subTask.sub_task_description)}
                                                  </div>
                                                </div>
                                              }
                                            </div>
                                            <div className="btn-wrp">
                                              <div className="avatar-title-wrp" style={{paddingTop: "10px"}}>
                                                { subTask.assigned_by && (
                                                  <div className="image-wrp">
                                                    <Avatar
                                                      src={
                                                        subTask.assigned_by
                                                          .profile_picture
                                                          ? `${process.env.REACT_APP_BACKEND_URL}/${subTask.assigned_by.profile_picture}`
                                                          : icon
                                                      }
                                                      alt={subTask.assigned_by_id}
                                                      className="avatar-small"
                                                      name={subTask.assigned_by.name}
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                              <button
                                                className="btn btn-table large"
                                                onClick={(e) => getEditSubTaskForm(e, subTask.id)}
                                                data-bs-toggle="collapse"
                                              >
                                                Edit Sub Task
                                              </button>
                                            </div>
                                            <CommentsSection
                                              pending={pending}
                                              allComments={subTask.comments !== null ? JSON.parse(subTask.comments) : []}
                                              parentTaskId={subTask.parent_task_id}
                                              subTaskId={subTask.id}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                          <button
                            className={`add-subtask-btn ${
                              showSubTaskForm ? "show-sub-task-form" : ""
                            }`}
                            onClick={() => {
                              resetSubTaskForm();
                              setShowSubTaskForm(true);
                              setSubTaskQaAssigneeImage(true);
                              // setEditParentTask({ ...editParentTask, qa_assignee: editParentTask.qa_assignee, qa_assignee_id: editParentTask.qa_assignee_id });
                              setSubTask({ ...subTask, qa_assignee: editParentTask.qa_assignee, qa_assignee_id: editParentTask.qa_assignee_id });
                            }}
                          >
                            Add a Sub Task
                          </button>
                        </div>
                      </div>
                      <div
                        className={`add-sub-task ${
                          showSubTaskForm ? "show-sub-task-form" : ""
                        }`}
                      >
                        <div className="form-wrp">
                          <form
                            encType="multipart/form-data"
                            onSubmit={createSubTask}
                            id="SUB_TASK_FORM"
                          >
                            <div className="form-fields-wrp">
                              <div className="form-fields-inner-wrp">
                                <div className="form-group">
                                  <label
                                    htmlFor="sub_task_name"
                                    className="form-label"
                                  >
                                    Task Name
                                  </label>
                                  <input
                                    type="text"
                                    name="sub_task_name"
                                    onBlur={handleSubTaskInput}
                                    defaultValue={subTask.sub_task_name}
                                    className="form-control"
                                    id="sub_task_name"
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {subTaskErrorList.sub_task_name}
                                  </small>
                                </div>
                                <div className="form-row">
                                  <div className="form-group col col-12 col-xxl-8">
                                    <div className="form-group">
                                      <div className="form-group">
                                        <label
                                          htmlFor="sub_task_description"
                                          className="form-label"
                                        >
                                          Description
                                        </label>
                                        <FormWYSIWYG
                                          value={subTaskDescription}
                                          onChange={handleSubTaskDescription}
                                          id={`sub_task_description_${subTask.id}`}
                                          name="sub_task_description"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group col col-12 col-xxl-4">
                                    <div className="form-group">
                                      <label
                                        htmlFor="sub_task_duration"
                                        className="form-label"
                                      >
                                        Start & End Date
                                      </label>
                                      <DateRangePicker
                                        onChange={setSubTaskDateRange}
                                        value={subTaskDateRange}
                                        format="yy-MM-dd"
                                        yearPlaceholder="yy"
                                        monthPlaceholder="mm"
                                        dayPlaceholder="dd"
                                        className="my-calender"
                                        id="sub_task_duration"
                                      />
                                      <small className="text-danger">
                                        {subTaskErrorList.sub_task_duration}
                                      </small>
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="estimate_time"
                                        className="form-label"
                                      >
                                        Estimate
                                      </label>
                                      <div className="max-hour-input-wrp">
                                        <input
                                          type="number"
                                          name="estimate_time"
                                          onBlur={handleSubTaskInput}
                                          defaultValue={subTask.estimate_time}
                                          min="0"
                                          max="9999"
                                          step="0.01"
                                          id="estimate_time"
                                          className="form-control"
                                          aria-label=""
                                        />
                                      </div>
                                      <small className="text-danger">
                                        {subTaskErrorList.estimate_time}
                                      </small>
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="task_type_id"
                                        className="form-label"
                                      >
                                        Type
                                      </label>
                                      <FormDropdown
                                        value={subTask.task_type_id}
                                        name="task_type_id"
                                        id="task_type_id"
                                        options={getDropdownOptions(taskTypes)}
                                        isSearchable={true}
                                        isDisabled={pending}
                                        onChange={handleSubTaskDropdown}
                                        placeholder="Task Type"
                                      />
                                      <small className="text-danger">
                                        {subTaskErrorList.task_type_id}
                                      </small>
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="task_status_id"
                                        className="form-label"
                                      >
                                        Status
                                      </label>
                                      <FormDropdown
                                        value={subTask.task_status_id}
                                        name="task_status_id"
                                        id="task_status_id"
                                        options={getDropdownOptions(taskStatuses)}
                                        isSearchable={true}
                                        isDisabled={pending}
                                        onChange={handleSubTaskDropdown}
                                        placeholder="Task Status"
                                      />
                                      <small className="text-danger">
                                        {subTaskErrorList.task_status_id}
                                      </small>
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="assignee_id"
                                        className="form-label"
                                      >
                                        Assignee
                                      </label>
                                      <FormDropdown
                                        value={subTask.assignee_id}
                                        name="assignee_id"
                                        id="assignee_id"
                                        options={getDropdownOptions(taskAssignees)}
                                        isSearchable={true}
                                        isDisabled={pending}
                                        onChange={handleSubTaskDropdown}
                                        placeholder="Task Assignee"
                                      />
                                      <small className="text-danger">
                                        {subTaskErrorList.assignee_id}
                                      </small>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="qa_assignee_id" className="form-label">
                                        QA
                                      </label>
                                      { subTaskQaAssigneeImage && subTask.qa_assignee ? (
                                        <div className="qa-manager-wrp">
                                          <Avatar
                                            src={
                                              subTask.qa_assignee.profile_picture
                                                ? `${process.env.REACT_APP_BACKEND_URL}/${subTask.qa_assignee.profile_picture}`
                                                : icon
                                            }
                                            alt={subTask.qa_assignee_id}
                                            onClick={deleteSubTaskQaAssigneeImage}
                                            closeButton={true}
                                            name={subTask.qa_assignee.name}
                                          />
                                        </div>
                                      ) : (
                                        <FormDropdown
                                          value={subTask.qa_assignee_id}
                                          name="qa_assignee_id"
                                          id="qa_assignee_id"
                                          options={getDropdownOptions(qaManagers)}
                                          isSearchable={true}
                                          isDisabled={pending}
                                          onChange={handleSubTaskDropdown}
                                          placeholder="QA Assignee"
                                        />
                                      )}
                                      <small className="text-danger">
                                        {projectErrorList.qa_assignee_id}
                                      </small>
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="uploads"
                                        className="form-label"
                                      >
                                        File Uploads
                                      </label>
                                      <div className="upload-wrp">
                                        <input
                                          type="file"
                                          name="uploads"
                                          multiple
                                          onChange={handleSubTaskUploads}
                                          className="form-control"
                                          id="uploads"
                                        />
                                      </div>
                                      <small className="text-danger">
                                        {subTaskErrorList.uploads}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="button-wrp">
                              <button
                                className="btn btn-table large"
                                type="button"
                                onClick={() => setShowSubTaskForm(false)}
                              >
                                Cancel
                              </button>
                              <button
                                disabled={pending ? "disabled" : ""}
                                type="submit"
                                className="btn btn-table large"
                              >
                                <span
                                  className={`spinner-border spinner-border-sm me-3 ${
                                    !pending ? "d-none" : ""
                                  }`}
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Create Sub Task
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div
                        className={`edit-sub-task ${
                          showEditSubTaskForm ? "edit-subtask-active" : ""
                        }`}
                      >
                        <div className="form-wrp">
                          <form
                            encType="multipart/form-data"
                            onSubmit={updateSubTask}
                            id="SUB_TASK_EDIT_FORM"
                          >
                            <div className="form-fields-wrp">
                              <div className="form-fields-inner-wrp">
                                <div className="form-group">
                                  <label
                                    htmlFor="sub_task_name"
                                    className="form-label"
                                  >
                                    Task Name
                                  </label>
                                  <input
                                    type="text"
                                    name="sub_task_name"
                                    onBlur={handleEditSubTaskInput}
                                    defaultValue={editSubTask.sub_task_name}
                                    className="form-control"
                                    id="sub_task_name"
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {editSubTaskErrorList.sub_task_name}
                                  </small>
                                </div>
                                <div className="form-row">
                                  <div className="form-group col col-12 col-xxl-8">
                                    <div className="form-group">
                                      <label
                                        htmlFor="sub_task_description"
                                        className="form-label"
                                      >
                                        Description
                                      </label>
                                      <FormWYSIWYG
                                        value={editSubTaskDescription}
                                        onChange={handleEditSubTaskDescription}
                                        id={`edit_sub_task_description_${editSubTask.id}`}
                                        name="edit_sub_task_description"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group col col-12 col-xxl-4">
                                    <div className="form-group">
                                      <label
                                        htmlFor="sub_task_duration"
                                        className="form-label"
                                      >
                                        Start & End Date
                                      </label>
                                      <DateRangePicker
                                        onChange={setEditSubTaskDateRange}
                                        value={editSubTaskDateRange}
                                        format="yy-MM-dd"
                                        yearPlaceholder="yy"
                                        monthPlaceholder="mm"
                                        dayPlaceholder="dd"
                                        className="my-calender"
                                        id="sub_task_duration"
                                      />
                                      <small className="text-danger">
                                        {editSubTaskErrorList.sub_task_duration}
                                      </small>
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="estimate_time"
                                        className="form-label"
                                      >
                                        Estimate
                                      </label>
                                      <div className="max-hour-input-wrp">
                                        <input
                                          type="number"
                                          name="estimate_time"
                                          onBlur={handleEditSubTaskInput}
                                          defaultValue={editSubTask.estimate_time}
                                          min="0"
                                          max="9999"
                                          step="0.01"
                                          id="estimate_time"
                                          className="form-control"
                                          aria-label=""
                                        />
                                      </div>
                                      <small className="text-danger">
                                        {editSubTaskErrorList.estimate_time}
                                      </small>
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="task_type_id"
                                        className="form-label"
                                      >
                                        Type
                                      </label>
                                      <FormDropdown
                                        value={editSubTask.task_type_id}
                                        name="task_type_id"
                                        id="task_type_id"
                                        options={getDropdownOptions(taskTypes)}
                                        isSearchable={true}
                                        isDisabled={pending}
                                        onChange={handleEditSubTaskDropdown}
                                        placeholder="Task Type"
                                      />
                                      <small className="text-danger">
                                        {editSubTaskErrorList.task_type_id}
                                      </small>
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="task_status_id"
                                        className="form-label"
                                      >
                                        Status
                                      </label>
                                      <FormDropdown
                                        value={editSubTask.task_status_id}
                                        name="task_status_id"
                                        id="task_status_id"
                                        options={getDropdownOptions(taskStatuses)}
                                        isSearchable={true}
                                        isDisabled={pending}
                                        onChange={handleEditSubTaskDropdown}
                                        placeholder="Task Status"
                                      />
                                      <small className="text-danger">
                                        {editSubTaskErrorList.task_status_id}
                                      </small>
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="assignee_id"
                                        className="form-label"
                                      >
                                        Assignee
                                      </label>
                                      { editSubTaskAssigneeImage && editSubTask.assignee ? (
                                        <div className="qa-manager-wrp">
                                          <Avatar
                                            src={
                                              editSubTask.assignee
                                                .profile_picture
                                                ? `${process.env.REACT_APP_BACKEND_URL}/${editSubTask.assignee.profile_picture}`
                                                : icon
                                            }
                                            alt={editSubTask.assignee_id}
                                            onClick={
                                              deleteEditSubTaskAssigneeImage
                                            }
                                            closeButton={true}
                                            name={editSubTask.assignee.name}
                                          />
                                        </div>
                                      ) : (
                                        <FormDropdown
                                          value={editSubTask.assignee_id}
                                          name="assignee_id"
                                          id="assignee_id"
                                          options={getDropdownOptions(taskAssignees)}
                                          isSearchable={true}
                                          isDisabled={pending}
                                          onChange={handleEditSubTaskDropdown}
                                          placeholder="Task Assignee"
                                        />
                                      )}
                                      <small className="text-danger">
                                        {editSubTaskErrorList.assignee_id}
                                      </small>
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="qa_assignee_id"
                                        className="form-label"
                                      >
                                        QA
                                      </label>
                                      { editSubTaskQaAssigneeImage && editSubTask.qa_assignee ? (
                                        <div className="qa-manager-wrp">
                                          <Avatar
                                            src={
                                              editSubTask.qa_assignee
                                                .profile_picture
                                                ? `${process.env.REACT_APP_BACKEND_URL}/${editSubTask.qa_assignee.profile_picture}`
                                                : icon
                                            }
                                            alt={editSubTask.qa_assignee_id}
                                            onClick={
                                              deleteEditSubTaskQaAssigneeImage
                                            }
                                            closeButton={true}
                                            name={editSubTask.qa_assignee.name}
                                          />
                                        </div>
                                      ) : (
                                        <FormDropdown
                                          value={editSubTask.qa_assignee_id}
                                          name="qa_assignee_id"
                                          id="qa_assignee_id"
                                          options={getDropdownOptions(qaManagers)}
                                          isSearchable={true}
                                          isDisabled={pending}
                                          onChange={handleEditSubTaskDropdown}
                                          placeholder="QA Assignee"
                                        />
                                      )}
                                      <small className="text-danger">
                                        {editSubTaskErrorList.qa_assignee_id}
                                      </small>
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="uploads"
                                        className="form-label"
                                      >
                                        File Uploads
                                      </label>
                                      <div className="upload-wrp">
                                        <input
                                          type="file"
                                          name="uploads"
                                          multiple
                                          onChange={handleEditSubTaskUploads}
                                          className="form-control"
                                          id="uploads"
                                        />
                                      </div>
                                      <small className="text-danger">
                                        {editSubTaskErrorList.uploads}
                                      </small>
                                    </div>
                                    {editSubTask.uploads &&
                                      JSON.parse(editSubTask.uploads).length >
                                        0 && (
                                        <div className="file-upload-wrp">
                                          <label
                                            htmlFor="uploads"
                                            className="form-label"
                                          >
                                            Files
                                          </label>
                                          <div className="upload-file-wrp">
                                            {JSON.parse(
                                              editSubTask.uploads
                                            ).map((upload) => (
                                              <a
                                                href={`${process.env.REACT_APP_BACKEND_URL}/${upload}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="upload-file-item"
                                              >{`${upload
                                                .split("/")
                                                .pop()}`}</a>
                                            ))}
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="button-wrp">
                              <button
                                className="btn btn-table large"
                                type="button"
                                onClick={() => setShowEditSubTaskForm(false)}
                              >
                                Cancel
                              </button>
                              <div className="del-update-btn-wrp">
                                <button
                                  type="button"
                                  onClick={(e) =>
                                    deleteSubTask(e, editSubTask.id)
                                  }
                                  className="colored-btn warning delete with-icon"
                                >
                                  Delete Sub Task
                                </button>
                                <button
                                  disabled={pending ? "disabled" : ""}
                                  type="submit"
                                  className="btn btn-table large"
                                >
                                  <span
                                    className={`spinner-border spinner-border-sm me-3 ${
                                      !pending ? "d-none" : ""
                                    }`}
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  Update Sub Task
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <FloatingTimer />
    </div>
  );
};

export default ViewProject;
